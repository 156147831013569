import React, { useState, useEffect } from 'react';

function Select ({tag, values, valueString, valuee, handleInputChange}) {

    
    return (


            <div className="indicator-details-values23">
              <label style={{marginLeft:"4%"}}>{tag}</label>
              <select className="indicator-length-input2"  style={{ marginRight: '0', width: "33%"}} value={valuee || ""} onChange={(e) => handleInputChange(valueString, e.target.value)}>
                <option value=""></option>
                    {values.map((value) => (
                        <option key={value} value={value}>{value}</option>
                    ))}
              </select>
            </div>


    );

};

export default Select;