import  MainChart  from '../strategyBuilder/mainChart/chart';
import { version } from '../../../charting_library';
import EquityCurve from './datavisuals/equityCurve';
import StatsPanel from './statspanel/statsPanel';
import ChatBar from './rightBar/rightbar';
import React, { useState, useEffect } from 'react';
import ListBar from '../utils/listbar/listbar';
import InnerBar from '../utils/innerbar/innerbar';
import { fetchData } from '../utils/helpers/fetchHelp';
import { handleInsert } from '../utils/helpers/handleInsert';
import { handleDelete } from '../utils/helpers/handleDelete';
import useAccessToken from '../utils/helpers/getAccessToken';



function PortfolioBuilder ({showToast}) {

    const getAccessToken = useAccessToken();
    const [portfolios, setPortfolios] = useState([]);
    const [selectedPortfolio, setSelectedPortfolio] = useState(null);
    const [trades, setTrades] = useState([]);
    const [selectedTrade, setSelectedTrade] = useState(null);


    useEffect(() => {
      const fetchPortfolios = async () => {
        const accessToken = await getAccessToken();
        fetchData(`${process.env.REACT_APP_PORTFOLIOS_API}`, setPortfolios, setSelectedPortfolio, accessToken);
      };
      fetchPortfolios();
    }, []);

    const handleDeletePortfolio = (portfolioId) => {
        handleDelete(portfolioId, portfolios, setPortfolios, selectedPortfolio, setSelectedPortfolio);
    };
  
    const handleInsertPortfolio = (portfolioId, portfolioName) => {
      handleInsert(portfolioId, portfolioName, portfolios, setPortfolios, setSelectedPortfolio);
    }  

    const handleTradeSelect = (trade) => {
        setSelectedTrade(trade);
        const tradeElement = document.getElementById(`trade-${trade.completetradeID}`);
        if (tradeElement) {
          tradeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };	  

    return (
        <>
        <div className = "tradebar">
          <ListBar
            items={portfolios}
            onItemSelect={setSelectedPortfolio}
            handleDelete={handleDeletePortfolio}
            selectedItem={selectedPortfolio}
            itemType="Portfolios"
            deleteEndpoint={process.env.REACT_APP_DELETE_PORTFOLIO_API}
          /> 
          
          <InnerBar
              items={trades}
              setItems={setTrades}
              selectedItem={selectedTrade}
              selectedListId={selectedPortfolio ? selectedPortfolio.id : null}
              onItemSelect={setSelectedTrade}
              itemType="Trades"
              endpoint={process.env.REACT_APP_PORTFOLIO_LIST_API}
              queryParamName="portfolioId"
          />




        </div>
        <div className="middle-section">
        <div className = "stock-chart">

        {/* this part of code has not been tested yet, specific is the selectedPortfolio */}
        {selectedTrade && <MainChart selectedTrade={selectedTrade}/>}
          </div>
          <div className="strategy-section">
          <div className = "equity-curve-whole">
          <div className = "equity-curve-name">
          </div>
          <div className = "equity-curve">
          <EquityCurve
               trades={trades}
               selectedStrategyId={selectedPortfolio ? selectedPortfolio.id : null}
               onTradeSelect = {handleTradeSelect}
          />
          </div>
          </div>
          <div className = "strategy-stats">
              <StatsPanel
                  selectedPortfolioId={selectedPortfolio ? selectedPortfolio.id : null}
              />
          </div>
          </div>
        </div>
        <div className = "chatbar">
            <ChatBar 
                selectedStrategy={selectedPortfolio}
                onStrategySelect = {setSelectedPortfolio}
                onTradeSelect = {setSelectedTrade}	
                handleInsert = {handleInsertPortfolio}
                showToast = {showToast}
            />
        </div>

  </>


    );
    
}


export default PortfolioBuilder;
