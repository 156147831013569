import React, { useState, useEffect } from 'react';
import Select from '../../utils/conditionRow/select';
import Input from '../../utils/conditionRow/input';


const indicatorInputsConfig = {
    dema: ['Length', 'Timeframe', 'Price Point', 'Point in Time'],
    ema: ['Length', 'Timeframe', 'Price Point', 'Point in Time'],
    sma: ['Length', 'Timeframe', 'Price Point', 'Point in Time'],
    low: ['Timeframe', 'Point in Time'],
    high: ['Timeframe', 'Point in Time'],
    open: ['Timeframe', 'Point in Time'],
    close: ['Timeframe', 'Point in Time'],
    volume: ['Timeframe', 'Point in Time'],
    time: ['Point in Time'],
  };


function Value ({order, indicator, length, timeframe, pricepoint, pointintime, specific, handleInputChange, operations, deleteOperation, updateOperation, addOperation}) {

    const requiredInputs = indicatorInputsConfig[indicator] || [];

    return (

        <div className="Value1">

            {requiredInputs.includes('Length') && (
            <Input tag = "Length:" typee = "number" valueString = {"length" + order} valuee = {length} handleInputChange = {handleInputChange}/>
            )}

            {requiredInputs.includes('Timeframe') && (
            <Select tag = "Timeframe:" values = {['1min', '3min', '5min']} valueString = {"timeframe" + order} valuee = {timeframe} handleInputChange = {handleInputChange}/>
            )}

            {requiredInputs.includes('Price Point') && (
            <Select tag = "Price Point:" values = {['open', 'high', 'low', 'close']} valueString = {"pricepoint" + order} valuee = {pricepoint} handleInputChange = {handleInputChange}/>
            )}

            {requiredInputs.includes('Point in Time') && (
            <Select tag = "Point in Time:" values = {['current', 'specifictime', 'lookback']} valueString = {"pointintime" + order} valuee = {pointintime} handleInputChange = {handleInputChange}/>
            )}


            {(pointintime === 'lookback' || pointintime === 'specifictime') && (
            <Input tag = "Specific:" typee = "null" valueString = {"specific" + order} valuee = {specific} handleInputChange = {handleInputChange}/>
            )}

            <div className="chatbar-operations">

                {!indicator &&(   
                    <p style = {{color: "red", margin: "0"}}>Please select an strategy</p>
                )}
            </div>

        </div>

    );

};

export default Value;