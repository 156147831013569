import "./toaster_pop.css";

function Toaster({ message, type }) {


  return (
    <div className={`toaster toaster--${type}`}>
      <p className="toaster-message">{message}</p>
    </div>
  );
}

export default Toaster;