import Table from '../utils/table';
import {useState, useEffect} from 'react';
import AddAccount from '../utils/add_account';
import { useLocation, useNavigate } from 'react-router-dom';


function Accounts({showToast}) {

    const [accounts, setAccounts] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const togglePopup = () => {
        setIsOpen(!isOpen);
      };

    useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const status = queryParams.get('status');
      const message = queryParams.get('message');

      if (message && status) {
          showToast(message, status);

          setTimeout(() => {
            navigate('/livetrading/accounts', { replace: true });
          }, 5000);  
      }
    }, [location, showToast]);

    const columns = [
        { key: 'account', header: 'Account' },
        { key: 'broker', header: 'Broker' },
        { key: 'type', header: 'Type' },
        { key: 'unrealized', header: 'Unrealized' },
        { key: 'realized', header: 'Realized' },
        { key: 'equity', header: 'Equity' },
        { key: 'bp', header: 'BP' },
        { key: 'overnightbp' , header: 'OvernightBP'}, 
    ];

    
    const fetchAccounts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_GET_ACCOUNTS}`);
        const data = await response.json();
        setAccounts(data);
      } catch (error) {
        console.error('Failed to fetch accounts:', error);
      }
    };

    useEffect(() => {
        fetchAccounts();
    }, []);

    
    const removeAccount = (accountId) => {
      const payload = {
        accountId: accountId,
      };
  
      fetch(`${process.env.REACT_APP_REMOVE_ACCOUNT}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
      .then(response => response.json())
      .then(data => {
        if (data.message) {
          showToast(data.message, 'success');
          handleRemoveAccount(accountId);
        } else if (data.error) {
          console.error(data.error);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
    }
  
    const handleRemoveAccount = (accountId) => {
      const updatedAccounts = accounts.filter(Account => Account.account !== accountId);
      setAccounts(updatedAccounts);
    };
  
  
    const handleRemoveClick = (Account) => {
      removeAccount(Account.account); 
    };
  
    const handleDetailsClick = (Account) => {
      console.log('detail clicked');
    }

    return (
    <div>
        <div className='headerBar'>
          <h1 style={{fontSize: '24px'}}> Accounts </h1>
          <button onClick={togglePopup} className="addStrategyButton">Add Account</button>
        </div>

        {isOpen && (
        <AddAccount togglePopup={togglePopup} allAccounts={accounts} setAllAccounts={setAccounts} showToast={showToast} />
        )}


        <Table
            columns = {columns}
            data = {accounts}
            onDetailClick={handleDetailsClick}
            onDeleteClick={handleRemoveClick}        
        />
        
    </div>
  );
}


export default Accounts;