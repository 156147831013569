import '../../backtesting/utils/rightBar/Rightbar.css';
import './detailsBar.css';



function DetailsBar ({trade}) {


    const exampleTrade = {
        tradeID: '1234',
        tradePrice: '123.45',
        tradeSize: '100',
        tradeType: 'Buy',
        tradeCommission: '0.00'
    };



    return (
        <div className="chatbar-container">
    
          <div className="chatbar-header">
            <div className="titletext">
                Trade Details
            </div>
          </div>
    
          <p className='standardText'>Account: </p>
          <p className='standardText'>Portfolio: </p>
          <p className='standardText'>Strategy: </p>
          <p className='standardText'>Entry Price: </p>
          <p className='standardText'>Exit Price: </p>
          <p className='standardText'>Type: </p>
          <p className='standardText'>Commission: </p>
          <p className='standardText'>Size: </p>
          <p className='standardText'>Entry Date-time: </p>
          <p className='standardText'>Exit Date-time: </p>
          <p className='standardText'>Duration: </p>
          <p className='standardText'>Profit/Loss $: </p>
          <p className='standardText'>Profit/Loss % (Account): </p>
          <p className='standardText'>Profit/Loss % (Portfolio): </p>
          <p className='standardText'>Profit/Loss % (Strategy): </p>
          <p className='standardText'>Profit/Loss % (Instrument): </p>
          <p className='standardText'>Status: </p>
          <p className='standardText'>Entry Order Type: </p>
          <p className='standardText'>Entry Order Route: </p>
          <p className='standardText'>Exit Order Type: </p>
          <p className='standardText'>Exit Order Route: </p>

        </div>

    );

}

export default DetailsBar;