import React, { useState, useEffect } from 'react';
import './conditionRow.css';
import Value from './value';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';
import { options } from '../helpers/options';
import { customStyles } from '../helpers/styles';
import { setNestedValue } from '../helpers/createDictionaryHelper'

const ConditionRow = ({ id, conditionn, onConditionChange,  onDelete }) => {
  const [leftOperations, setLeftOperations] = useState(conditionn?.Value1?.operations || []);
  const [rightOperations, setRightOperations] = useState(conditionn?.Value2?.operations || []);


  const [condition, setCondition] = useState(conditionn);

  const updateLeftOperation = (index, updatedLeftOperation) => {
    const updatedOperations = leftOperations.map((op, i) => 
    i === index ? updatedLeftOperation : op);
    setLeftOperations(updatedOperations);
    handleInputChange('Value1.operations', updatedOperations);
  };

  const updateRightOperation = (index, updatedRightOperation) => {
    const updatedOperations = rightOperations.map((op, i) => 
    i === index ? updatedRightOperation : op);
    setRightOperations(updatedOperations);
    handleInputChange('Value2.operations', updatedOperations);
  };

  const handleInputChange = (name, value) => {

    let newCondition = { ...condition };

    //this clears all attributes when user changes indicator
    if (name.endsWith('.type.name')) {
      const prefix = name.split('.type.name')[0];
      Object.keys(newCondition).forEach(key => {
        if (key.startsWith(prefix) && key !== name) {
          delete newCondition[key];
        }
      });

      if (newCondition[prefix] && newCondition[prefix].type) {
        Object.keys(newCondition[prefix].type).forEach(key => {
          if (key !== 'name') {
            delete newCondition[prefix].type[key];
          }
        });
      }

      if (prefix === 'Value1') {
        setLeftOperations([]);
      }
      
      if (prefix === 'Value2') {
        setRightOperations([]);
      }

    }

    //possible error but unlikely is that old name is not cleared and has error when overwriting it here in next line
    newCondition = setNestedValue({ ...newCondition }, name, value);
    setCondition(newCondition);
    onConditionChange(newCondition);
  };

  const addLeftOperation = () => {
    const newOperation = { id: uuidv4(), comparison: 'Add', valuee: '' };
    setLeftOperations([...leftOperations, newOperation]);
  };

  const addRightOperation = () => {
    const newOperation = { id: uuidv4(), comparison: 'Add', valuee: '' };
    setRightOperations([...rightOperations, newOperation]);
  };

  const deleteLeftOperation = (operationIndex) => {
    const updatedOperations = leftOperations.filter((_, index) => index !== operationIndex);
    setLeftOperations(updatedOperations);
    handleInputChange('Value1.operations', updatedOperations);
  };

  const deleteRightOperation = (operationIndex) => {
    const updatedOperations = rightOperations.filter((_, index) => index !== operationIndex);
    setRightOperations(updatedOperations);
    handleInputChange('Value2.operations', updatedOperations);
  };
  
  const handleSelectChange = (selectedOption) => {
    handleInputChange('Value1.type.name', selectedOption.value);
  };

  const handleSelectChange2 = (selectedOption) => {
    handleInputChange('Value2.type.name', selectedOption.value);
  };

  
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <div className="chatbar-condition-row">
      <div className = "chatbar-condition-row-values" >
      <button onClick={toggleDetails} className="detail-toggle-btn">+</button>
      <Select
        styles={customStyles}
        className="indicator-dropdown-select"
        value={options.find(option => option.value === (condition.Value1?.type?.name ?? ''))}
        onChange={handleSelectChange}
        options={options}
        isSearchable={true}
      />
      <select className="condition-dropdown"  value={condition.comparator} onChange={(e) => handleInputChange('comparator', e.target.value)}>
        <option value="">-</option>
        <option value="greaterThan">&gt;</option>
        <option value="lessThan">&lt;</option>
        <option value="greaterThanOrEqual">&gt;=</option>
        <option value="lessThanOrEqual">&lt;=</option>
        <option value="equal">=</option>
      </select>
      <Select
        styles={customStyles}
        className="indicator-dropdown-select"
        value={options.find(option => option.value === (condition.Value2?.type?.name ?? ''))}
        onChange={handleSelectChange2}
        options={options}
        isSearchable={true}
      />
      <button onClick={() => onDelete(id)} className="delete-condition">
        Remove
      </button>
      </div>
      {showDetails && (
        <div className="indicator-details">
          
          <Value 
            order = "Value1"
            value={condition.Value1}
            handleInputChange={handleInputChange} 
            operations = {leftOperations}
            deleteOperation = {deleteLeftOperation}
            updateOperation = {updateLeftOperation}
            addOperation = {addLeftOperation}
          />

          <Value
            order = "Value2"
            value={condition.Value2}
            handleInputChange={handleInputChange}
            operations = {rightOperations}
            deleteOperation = {deleteRightOperation}
            updateOperation = {updateRightOperation}
            addOperation = {addRightOperation}
          />

        </div>

      )}
    </div>    
  );
};

export default ConditionRow;
