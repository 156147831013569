const registerUser = async (accessToken) => {
    const API_ENDPOINT = process.env.REACT_APP_REGISTER_USER;

    try {
        const response = await fetch(API_ENDPOINT, {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`, 
            },
            body: JSON.stringify({}), 
        });

        const data = await response.json();

        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.Message || 'Failed to register user');
        } else {
            console.log(data.message);
        }
    } catch (error) {
        console.error('Error during user registration:', error);
        throw error; 
    }
};

export default registerUser;
