import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import  MainChart  from './mainChart/chart';
import { version } from '../../../charting_library';
import EquityCurve from './datavisuals/equityCurve';
import StatsPanel from './statspanel/statsPanel';
import ChatBar from './chatbar/chatBar';
import ListBar from '../utils/listbar/listbar';
import InnerBar from '../utils/innerbar/innerbar';
import { fetchData } from '../utils/helpers/fetchHelp';
import { handleInsert } from '../utils/helpers/handleInsert';
import { handleDelete } from '../utils/helpers/handleDelete';
import useAccessToken from '../utils/helpers/getAccessToken';



function StrategyBuilder ( {showToast} ) {

	const getAccessToken = useAccessToken();
	const [strategies, setStrategies] = useState([]);
  	const [selectedStrategy, setSelectedStrategy] = useState(null);
  	const [trades, setTrades] = useState([]);
  	const [selectedTrade, setSelectedTrade] = useState(null);
	const [selectedRange, setSelectedRange] = useState([]);
  


  	useEffect(() => {
		const fetchStrategies = async () => {
			const accessToken = await getAccessToken();
			fetchData(`${process.env.REACT_APP_STRATEGIES_API}`, setStrategies, setSelectedStrategy, accessToken);
		};
		fetchStrategies();
	}, []);

	const handleDeleteStrategy = (strategyIds, setLastSelectedIndex) => {
		handleDelete(strategyIds, strategies, setStrategies, selectedStrategy, setSelectedStrategy, setLastSelectedIndex);
	};
    
  	const handleInsertStrategy = (strategyId, StrategyName) => {
		handleInsert(strategyId, StrategyName, strategies, setStrategies, setSelectedStrategy);
	}  

	const handleItemSelect = (trade) => {
		setSelectedTrade(trade);
	};

  	const handleTradeSelect = (trade) => {
		setSelectedTrade(trade);
  		const tradeElement = document.getElementById(`trade-${trade.completetradeID}`);
  		if (tradeElement) {
  		  tradeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  		}
  	};	  

	
	return (
      <>
			<div className = "tradebar">
				<ListBar
            		items={strategies}
            		onItemSelect={setSelectedStrategy}
            		handleDelete={handleDeleteStrategy}
            		selectedItem={selectedStrategy}
					selectedRange={selectedRange}
					setSelectedRange={setSelectedRange}
            		itemType="Strategies"
            		deleteEndpoint={process.env.REACT_APP_DELETE_STRATEGY_API}
          		/> 
	
          		<InnerBar
            	  items={trades}
            	  setItems={setTrades}
            	  selectedItem={selectedTrade}
            	  selectedListId={selectedStrategy ? selectedStrategy.id : null}
            	  onItemSelect={handleItemSelect}
            	  itemType="Trades"
            	  endpoint={process.env.REACT_APP_TRADES_LIST_API}
            	  queryParamName="strategyId"
          		/>

			</div>
			<div className="middle-section">
			<div className = "stock-chart">
			{selectedTrade && <MainChart selectedTrade={selectedTrade}/>}
			  </div>
			  <div className="strategy-section">
			  <div className = "equity-curve-whole">
			  <div className = "equity-curve-name">
			  </div>
			  <div className = "equity-curve">
			  <EquityCurve
			  	 trades={trades}
			  	 selectedStrategyId={selectedStrategy ? selectedStrategy.id : null}
				 onTradeSelect = {handleTradeSelect}
			  />
			  </div>
			  </div>
			  <div className = "strategy-stats">
			  	<StatsPanel
			  		selectedStrategyId={selectedStrategy ? selectedStrategy.id : null}
			  	/>
			  </div>
			  </div>
			</div>
			<div className = "chatbar">
				<ChatBar 
					selectedStrategy={selectedStrategy}
					onStrategySelect = {setSelectedStrategy}
					onTradeSelect = {setSelectedTrade}	
					handleInsert = {handleInsertStrategy}
					showToast = {showToast}
				/>
			</div>

      </>


    );
    
}


export default StrategyBuilder;
