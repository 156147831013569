import Table from '../utils/table';
import {useState, useEffect} from 'react';




function Positions() {

    const [positions, setPositions] = useState([]);

    const columns = [
        { key: 'symbol', header: 'Symbol' },
        { key: 'broker', header: 'Broker' },
        { key: 'account', header: 'Account' },
        { key: 'shares', header: 'Shares' },
        { key: 'avgcost', header: 'Avgcost' },
        { key: 'marketvalue', header: 'Market Value' },
        { key: 'unrealizedpnl', header: 'Unrealized P&L' },
        { key: 'realizedpnl', header: 'Realized' },
        { key: 'side' , header: 'Side'}, 
        { key: 'currentprice', header: 'Current Price'}
    ];

    
    const fetchPositions = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_GET_POSITIONS}`);
            const data = await response.json();
            setPositions(data);
          } catch (error) {
            console.error('Failed to fetch positions:', error);
          }
    
    };

    useEffect(() => {
        fetchPositions();
    }, []);

    return (
    <div>
        <h1 style={{paddingLeft: '0.5%', fontSize: '24px'}}> Positions </h1>
        <Table
            columns = {columns}
            data = {positions}        
        />
        
    </div>
  );
}


export default Positions;