import './add_strategy.css';
import React, { useState, useEffect } from 'react';

function Popup({togglePopup, liveStrats, setLive}) {

  const [strategies, setStrategies] = useState([]);
  const [selectedStrategy, setSelectedStrategy] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);


	const fetchStrategies = async () => {
		try {
		  const response = await fetch(`${process.env.REACT_APP_HISTORICAL_STRATEGIES_API}`);
		  const data = await response.json();
		  setStrategies(data);
      if (data && data.length > 0) {
			setSelectedStrategy(data[0].id); 
		  }
		} catch (error) {
		  console.error('Failed to fetch strategies:', error);
		}
	  };

  const fetchAccounts = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_GET_ACCOUNT_NAMES}`);
      const data = await response.json();
      setAccounts(data);
      if (data && data.length > 0) {
        setSelectedAccount(data[0]); 
      }
    }
    catch (error) {
      console.error('Failed to fetch accounts:', error);
    }
  };

	useEffect(() => {
	  fetchStrategies();
    fetchAccounts();
  }, []);

  const handleAccountChange = (e) => {
    const selectedId = e.target.value;
    const selected = accounts.find(account => account.id === selectedId);
    setSelectedAccount(selected); 
  };


  const handleLiveRunClick = async () => {
    
    const payload = {
      strategyId: selectedStrategy,
      accountId: selectedAccount.id,
      broker: selectedAccount.broker,
    };
    console.log('Running strategy with payload:', payload);
    try {
      const response = await fetch(`${process.env.REACT_APP_RUN_LIVE_STRATEGY}`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        console.log('Strategy is now deployed successfully');
        let int_strategy = Number(selectedStrategy);
        const matchingStrategy = strategies.find(strategy => strategy.id === int_strategy);
        console.log('Matching strategy:', matchingStrategy);
        const updatedStrategies = [...liveStrats, matchingStrategy];
        setLive(updatedStrategies);
      }
    } catch (error) {
      console.error('There was an error running the strategy', error);
    }

  }
  
  const handleClick = () => {
    handleLiveRunClick();
    togglePopup();
  }

  return (
    <div>
        <div className="popup-box">
          <div className="box">
            <h2>Select Strategy</h2>
            
            <div className="select-container-add-strategy">
               <p className ="detail-text-popup">Select Strategy:</p>
               <select 
                 className="color-select"
                 value={selectedStrategy}
                 onChange={(e) => setSelectedStrategy(e.target.value)}
               >
                 {strategies.map((strategy) => (
                     <option key={strategy.id} value={strategy.id}>
                         {strategy.name} 
                     </option>
                 ))}
               </select>

            </div>
            <div className="select-container-add-strategy">
              <p className ="detail-text-popup">Select Account:</p>

              <select
                className="color-select"
                value={selectedAccount?.id}
                onChange={handleAccountChange}
              >
                {accounts.map((account) => (
                  <option key={account.id} value={account.id}>
                    {account.id}
                  </option>
                ))}
              </select>


            </div>

            <button onClick={togglePopup}>Cancel</button>
            <button onClick={handleClick}>Confirm</button>
          </div>
        </div>
      
    </div>
  );
}

export default Popup;
