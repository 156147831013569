import React, { useEffect, useRef, useState,useMemo,  memo } from 'react';
import './chart.css';
import { widget } from '../../../../charting_library';
import { createDataFeed  } from '../../utils/tradingViewUtils';


function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}


const MainChart = memo(({selectedTrade}) => {


	const isEntryOrExitOnly = selectedTrade?.chartType === "EntryOnly" || selectedTrade?.chartType === "ExitOnly";
    const isEntryOnly = selectedTrade?.chartType === "EntryOnly"
	const isExitOnly = selectedTrade?.chartType === "ExitOnly"


	const historicalData = useRef(null);
	const indicatorData = useRef(null);

	const chartContainerRef = useRef();
	const moment = require('moment');

	//define the symbol
	if (Array.isArray(selectedTrade)) {
		var TheSymbol = selectedTrade[0].symbol;
		var TheStrategyID = selectedTrade[0].strategyId;
	} else {
		var TheSymbol = selectedTrade.symbol;
		var TheStrategyID = selectedTrade.strategyId;
	}
	
	let fromDateOrg
	let toDateOrg
	
	//define from and to dates for multiple trades or all trades in strategy
	if (Array.isArray(selectedTrade)) {
		fromDateOrg = selectedTrade[0].date;
		toDateOrg = selectedTrade[selectedTrade.length - 1].endDate;
	} else {
		fromDateOrg = selectedTrade.date;
		toDateOrg = selectedTrade.endDate;
	}

	if (isEntryOnly) {
		toDateOrg = fromDateOrg;
	}

	if (isExitOnly) {	
		fromDateOrg = toDateOrg;
	}

	const formattedFromDate = formatDate(fromDateOrg);
	const formattedToDate = formatDate(toDateOrg);
	
    //For better, cleaner code, we can shares the code from trade details where it shows duration of trade
	const date_day_difference = Math.floor((new Date(formattedToDate) - new Date(formattedFromDate)) / (1000 * 60 * 60 * 24));

	let newInterval;
	let days_to_add;

	if (date_day_difference <= 0) {
		newInterval = "1";
		days_to_add = 3;
	} else if (date_day_difference > 0 && date_day_difference <= 5) {
		newInterval = "5";
		days_to_add = 5;
	} else if (date_day_difference > 5 && date_day_difference <= 30) {
		newInterval = "30";
		days_to_add = 30;
	} else if (date_day_difference > 30 && date_day_difference <= 60) {
		newInterval = "60";
		days_to_add = 60;
	} else  {
		newInterval = "1D";
		days_to_add = 250;
	}


	const fromDate = new Date(formattedFromDate);
	const toDate = new Date(formattedToDate);

	fromDate.setDate(fromDate.getDate() + 1);
	const nextDayFormattedDate = formatDate(fromDate.toISOString().substring(0, 10));	
	
	toDate.setDate(toDate.getDate() + 1);
	const nextDayFormattedEndDate = formatDate(toDate.toISOString().substring(0, 10));


	const timeFromDb = '00:00:00'; 

	const dateTimeUtcUnix = moment.utc(`${nextDayFormattedDate} ${timeFromDb}`, 'YYYY-MM-DD HH:mm:ss');
	const dateTimeUtcUnixEnd = moment.utc(`${nextDayFormattedEndDate} ${timeFromDb}`, 'YYYY-MM-DD HH:mm:ss');


	const plusOneMinute = dateTimeUtcUnix.clone().add(800, 'minutes').unix();
	const plusMultipleMinutes = dateTimeUtcUnix.clone().add(1300, 'minutes').unix();


	//setting to change based on default time frame
	const minusThreeDays = dateTimeUtcUnix.clone().subtract(days_to_add, 'days').unix();
	const plusThreeDaysEnd = dateTimeUtcUnixEnd.clone().add(days_to_add, 'days').unix();

	useEffect(() => {

		const fetchIndicatorDetails = async () => {
            try {  
              const response = await fetch(`${process.env.REACT_APP_GET_INDICATOR_DETAILS}?strategyId=${TheStrategyID}`);
              const data = await response.json();
			  indicatorData.current = data;
            } catch (error) {
              console.error('Failed to fetch indicator details:', error);
            }
          };
		
		
		const widgetOptions = {
			
			symbol: TheSymbol,	
			datafeed: createDataFeed(minusThreeDays, plusThreeDaysEnd, date_day_difference, TheStrategyID, historicalData, isEntryOrExitOnly),
			interval: newInterval,																	
			timezone: "America/New_York",
			container: chartContainerRef.current,
			library_path: '/charting_library/',
			locale: getLanguageFromURL() || 'en',
			disabled_features: ['use_localstorage_for_settings'],
			enabled_features: ['study_templates'],
			charts_storage_url: 'https://saveload.tradingview.com',
			charts_storage_api_version: '1.1',
			timeframe: { from: plusOneMinute, to: plusMultipleMinutes },						
			client_id: 'tradingview.com',
			user_id: 'public_user_id',
			
			fullscreen: false,
			autosize: true,
			studies_overrides: {},


			custom_indicators_getter: async (PineJS) => {
				await fetchIndicatorDetails();
				const indicators = indicatorData.current.map(indicator_data => {
				  const metainfo = {
					_metainfoVersion: 51,
					id: `${indicator_data.name}@tv-basicstudies-1`,
					name: indicator_data.name,
					description: indicator_data.name,
					shortDescription: indicator_data.name,
					is_price_study: true,
					isCustomIndicator: true,
					format: {
					  type: "price",
					  precision: 2,
					},
					plots: indicator_data.plots.map((plot, index) => ({
					  id: `plot_${index}`,
					  type: plot.type || "line",
					})),
					defaults: {
					  styles: indicator_data.plots.reduce((styles, plot, index) => {
						styles[`plot_${index}`] = {
						  linestyle: plot.linestyle || 0,
						  linewidth: plot.linewidth || 1,
						  plottype: plot.plottype || 0,
						  trackPrice: !!plot.trackPrice,
						  transparency: plot.transparency || 0,
						  visible: plot.visible !== false,
						  color: plot.color || '#000080', 
						};
						return styles;
					  }, {}),
					  inputs: {},
					},
					styles: indicator_data.plots.reduce((styles, plot, index) => {
					  styles[`plot_${index}`] = {
						title: plot.title || `Plot ${index}`,
						histogramBase: plot.histogramBase || 0,
						joinPoints: !!plot.joinPoints,
					  };
					  return styles;
					}, {}),
					inputs: [],
				  };
			  
				  const constructor = function () {
					this.main = function (context, inputCallback) {
					  this._context = context;
					  this._input = inputCallback;
					  const symbolTime = PineJS.Std.time(this._context);
					  if (Number.isNaN(symbolTime)) {
						return; 
					  }
					  
					  const matchingData = historicalData.current.find(item => item.unixTime === symbolTime);
					  
					  if (!matchingData) return;
			  
					  return indicator_data.plots.map(plot => matchingData[plot.key]);
					};
				  };
			  
				  return { name: indicator_data.name, metainfo, constructor };
				});
			  
				return Promise.resolve(indicators);
			  },

		};

		const tvWidget = new widget(widgetOptions);

		tvWidget.onChartReady(() => {
			tvWidget.headerReady().then(() => {


				indicatorData.current.map(indicator_data => {
					tvWidget.activeChart().createStudy(indicator_data.name)
				});
				
				
				function processTrade(trade) {
					let icon, angle, color;

					//Even though code works, it is not clean. Look for a better way to prevent bugs, possibly the way long_short is stored in the database
					if (isEntryOrExitOnly) {
						if (isEntryOnly) {
							if (trade.long_short === 'long') {
								if (trade.side === 'buy') {
									icon = 0xf0da; 
									angle = 6.28319; 
									color = 'green';
								} else {
									return;
								}
							} else if (trade.long_short === 'short') {
								if (trade.side === 'sell') {
									icon = 0xf0da; 
									angle = 3.14159; 
									color = 'red';
								} else {
									return;
								}
							}
						} else if (isExitOnly) {
							if (trade.long_short === 'long') {
								if (trade.side === 'sell') {
									icon = 0xf0da; 
									angle = 3.14159; 
									color = 'red';
								} else {
									return;
								}
							} else if (trade.long_short === 'short') {
								if (trade.side === 'buy') {
									icon = 0xf0da; 
									angle = 6.28319; 
									color = 'green';
		  
								} else {
									return;
								}
							}
						}

					} else {

						if (trade.side === 'buy') {
						  icon = 0xf0da; 
						  angle = 6.28319; 
						  color = 'green';
						} else if (trade.side === 'sell') {
						  icon = 0xf0da; 
						  angle = 3.14159; 
						  color = 'red';
						}
					}
			  
					tvWidget.activeChart().createShape(
					  {
						time: trade.unix_msec / 1000, 
						price: parseFloat(trade.price),
					  },
					  {
						shape: "icon",
						icon: icon,
						zOrder: "top",
						overrides: { 
						  size: 25, 
						  angle: angle, 
						  color: color 
						}, 
						lock: true,
						disableSelection: true
					  }
					);
				}  
					
				
				if (Array.isArray(selectedTrade)) {
					selectedTrade.forEach((item) => {
					  item.trades.forEach((trade) => {
						processTrade(trade);
					  });
					});
				} else {
					selectedTrade.trades.forEach((trade) => {
					  processTrade(trade);
					});
				}
				  

				const button = tvWidget.createButton();
				button.setAttribute('title', 'Click to show a notification popup');
				button.classList.add('apply-common-tooltip');
				button.addEventListener('click', () => tvWidget.showNoticeDialog({
					title: 'Notification',
					body: 'TradingView Charting Library API works correctly',
					callback: () => {
						console.log('Noticed!');
					},
				}));

				button.innerHTML = 'Check API';
			});

		});

		return () => {
			tvWidget.remove();
		};
	});

	return (
		<div
			ref={chartContainerRef}
			className={'MainChart2'}
		/>
	);
});

export default MainChart;