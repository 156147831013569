import React, { useState, useEffect } from 'react';
import OperationRow from './operationRow';
import Select from './select';
import Input from './input';


const indicatorInputsConfig = {
    dema: ['Length', 'Timeframe', 'Price Point', 'Point in Time'],
    ema: ['Length', 'Timeframe', 'Price Point', 'Point in Time'],
    sma: ['Length', 'Timeframe', 'Price Point', 'Point in Time'],
    bbands: ['Band', 'Length', 'nbdevup', 'nbdevdn', 'Timeframe', 'Price Point', 'Point in Time'],
    low: ['Timeframe', 'Point in Time'],
    high: ['Timeframe', 'Point in Time'],
    open: ['Timeframe', 'Point in Time'],
    close: ['Timeframe', 'Point in Time'],
    volume: ['Timeframe', 'Point in Time'],
    time: ['Point in Time'],
    number: ['Number Value'],
    averageprice: [],
  };


function Value ({order, value, handleInputChange, operations, deleteOperation, updateOperation, addOperation}) {

    const requiredInputs = indicatorInputsConfig[value?.type?.name] || [];

    return (

        <div className="Value1">
            
            {requiredInputs.includes('Band') && (
            <Select tag = "Band:" values = {['Top', 'Middle', 'Bottom']} valueString = {order + ".type.band"} valuee = {value.band} handleInputChange = {handleInputChange}/>
            )}

            {requiredInputs.includes('Length') && (
            <Input tag = "Length:" typee = "number" valueString = {order + ".type.timeperiod"} valuee = {value.type.timeperiod} handleInputChange = {handleInputChange}/>
            )}

            {requiredInputs.includes('Number Value') && (
            <Input tag = "Number Value:" typee = "number" valueString = {order + ".type.numberValue"} valuee = {value.type.numberValue} handleInputChange = {handleInputChange}/>
            )}

            {requiredInputs.includes('nbdevup') && (
            <Input tag = "Dev Up:" typee = "number" valueString = {order + ".type.nbdevup"} valuee = {value.type.nbdevup} handleInputChange = {handleInputChange}/>
            )}

            {requiredInputs.includes('nbdevdn') && (
            <Input tag = "Dev Down:" typee = "number" valueString = {order + ".type.nbdevdn"} valuee = {value.type.nbdevdn} handleInputChange = {handleInputChange}/>
            )}

            {requiredInputs.includes('Timeframe') && (
            <Select tag = "Timeframe:" values = {['1min', '5min', '30min' , '1hr', '1d']} valueString = {order + ".timeframe"} valuee = {value.timeframe} handleInputChange = {handleInputChange}/>
            )}

            {requiredInputs.includes('Price Point') && (
            <Select tag = "Price Point:" values = {['open', 'high', 'low', 'close']} valueString = {order + ".type.price"} valuee = {value.type.price} handleInputChange = {handleInputChange}/>
            )}

            {requiredInputs.includes('Point in Time') && (
            <Select tag = "Point in Time:" values = {['current', 'specifictime', 'lookback']} valueString = {order + ".pointintimeType"} valuee = {value.pointintimeType} handleInputChange = {handleInputChange}/>
            )}

            {(value?.pointintimeType === 'specifictime') && (
            <Input tag = "Specific:" typee = "null" valueString = {order + ".specificTime"} valuee = {value.specificTime} handleInputChange = {handleInputChange}/>
            )}

            {(value?.pointintimeType === 'lookback') && (
            <Input tag = "Specific:" typee = "null" valueString = {order + ".lookback"} valuee = {value.lookback} handleInputChange = {handleInputChange}/>
            )}


            <div className="chatbar-operations">
                {operations.map((operation, index) => (
                  <OperationRow 
                    key={operation.id} 
                    operation = {operation}
                    onOperationChange={(updatedOperation) => updateOperation(index, updatedOperation)} 
                    onDelete={() => deleteOperation(index)}
                  />
                ))}
                {value?.type?.name && (
                <div className="add-operation">
                  <button onClick={addOperation} type="button">Add Operation</button>
                </div>
                )} 
                {!value?.type?.name &&(   
                    <p style = {{color: "red", margin: "0"}}>Please select an indicator</p>
                )}
            </div>

        </div>

    );

};

export default Value;