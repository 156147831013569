import React, { useState, useEffect } from 'react';
import './conditionRow.css';
import Value from './value';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';
import { customStyles } from '../../utils/helpers/styles';


const ConditionRow = ({ id, conditionn, onConditionChange,  onDelete }) => {

  const [strategies, setStrategies] = useState([]);
  //think about how this could be more secure
  const initialState = {
    strategyName: conditionn?.strategyName || '',
    strategyId: conditionn?.strategyId || '',
  };



	const fetchStrategies = async () => {
		try {
		  const response = await fetch(`${process.env.REACT_APP_HISTORICAL_STRATEGIES_API}`);
		  const data = await response.json();
		  setStrategies(data);
		} catch (error) {
		  console.error('Failed to fetch strategies:', error);
		}
	};

  const transformedStrategies = strategies.map(strategy => ({
    label: strategy.name,
    value: strategy.id
  }));

	useEffect(() => {
	  fetchStrategies();
	}, []);

  const [condition, setCondition] = useState(initialState);
  

  const handleInputChange = (selectedOption) => {
    const newCondition = { 
      ...condition, 
      strategyName: selectedOption.label, 
      strategyId: selectedOption.value 
    };
    
    setCondition(newCondition);
    onConditionChange(newCondition); 
  };



  return (
    <div className="chatbar-condition-row">
      <div className = "chatbar-condition-row-values" >
      <Select
        styles={customStyles}
        className="indicator-dropdown-select-portfolio"
        onChange={handleInputChange}
        options={transformedStrategies}
        isSearchable={true}
        value={transformedStrategies.find(option => option.value === (condition.strategyId ?? ''))}
      />

      <button onClick={() => onDelete(id)} className="delete-condition">
        Remove
      </button>
      </div>
    </div>    
  );
};

export default ConditionRow;
