export const setNestedValue = (obj, path, value) => {
    if (path.includes('.')) {
      const keys = path.split('.');
      const lastKey = keys.pop();
      let current = obj;
  
      keys.forEach(key => {
        if (!current[key]) {
          current[key] = {};
        }
        current = current[key];
      });
  
      current[lastKey] = value;
      return obj;
    } else {
      obj[path] = value;
      return obj;
    }
  };


