import Table from '../utils/table';
import {useState, useEffect} from 'react';
//no import for .css in this file



function Scanners() {

    const [scanners, setScanners] = useState([]);


    const columns = [
        { key: 'name', header: 'Scanner Name' },
        { key: 'strategy', header: 'Associated Strategy' },
        { key: 'status', header: 'Status' },
        { key: 'hits', header: 'Hits' },
      ];

    
    const fetchScanners = async () => {
        console.log('Fetching scanners');
    };

    useEffect(() => {
        fetchScanners();
    }, []);

    return (
    <div>
        <div className='headerBar'>
          <h1 style={{fontSize: '24px'}}> Scanners </h1>
          <button className="addStrategyButton">Add Scanner</button>
        </div>
        <Table
            columns = {columns}
            data = {scanners}        
        />
        
    </div>
  );
}


export default Scanners;