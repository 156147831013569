import React, { useState, useEffect } from 'react';
import ConditionRow from '../../utils/conditionRow/conditionRow';
import { v4 as uuidv4 } from 'uuid';
import '../../utils/rightBar/Rightbar.css';
import DateSelector from '../../utils/helpers/dateRow';
import AdvancedPopup from '../../utils/advancedPopUp/advancedPopup';


function ChatBar( {selectedScanner, handleInsert, showToast} ) {
  

  const [scannerName, setScannerName] = useState('');
  const [symbol, setSymbol] = useState('');
  const [list, setList ] = useState('');
  const [startYear, setStartYear] = useState('2024');
  const [startMonth, setStartMonth] = useState('01');
  const [startDay, setStartDay] = useState('01');
  const [endYear, setEndYear] = useState('2024');
  const [endMonth, setEndMonth] = useState('01');
  const [endDay, setEndDay] = useState('01');
  const [market, setMarket] = useState('');
  const [type, setType] = useState('');
  const [conditions, setConditions] = useState([]);



  useEffect(() => {
    if (selectedScanner != null) {
      const fetchDetailsForScanner = async () => {
        try {  
          const response = await fetch(`${process.env.REACT_APP_GET_SCANNER_DETAILS}?scannerId=${selectedScanner.id}`);
          const data = await response.json();
          
          setScannerName(data.name);
          setMarket(data.market);
          setType(data.type);
          setStartYear(data.start_year);
          setStartMonth(data.start_month);
          setStartDay(data.start_day);
          setEndYear(data.end_year);
          setEndMonth(data.end_month);
          setEndDay(data.end_day);
          setSymbol(data.symbol);
          setList(data.list);
          
        
          const loadedConditions = data.conditions.map(condition => ({
            id: uuidv4(),
            ...condition
          }));
        
          setConditions(loadedConditions);

        } catch (error) {
          console.error('Failed to fetch scanner details:', error);
        }
      };

      fetchDetailsForScanner();
    } else {
      setScannerName('');
      setStartYear('2024');
      setStartMonth('01');
      setStartDay('01');
      setEndYear('2024');
      setEndMonth('01');
      setEndDay('01');
      setMarket('');
      setSymbol('');
      setList('');
      setType('');
      setConditions([]);
    }
  }, [selectedScanner]);


  const [showEntryDetails, setEntryShowDetails] = useState(false);

  const toggleEntryDetails = () => {
    setEntryShowDetails(!showEntryDetails);
  };


  const clearScanner = () => {
    setScannerName('');
    setStartYear('2024');
    setStartMonth('01');
    setStartDay('01');
    setEndYear('2024');
    setEndMonth('01');
    setEndDay('01');
    setMarket('');
    setType('');
    setSymbol('');
    setList('');
    setConditions([]);
  };



  const handleRunClick = async () => {

    if (!scannerName) {
      showToast('Please enter a indicator name', 'error');
      return;
    }
    
    const payload = {
      name: scannerName,
      startYear: startYear,
      startMonth: startMonth,
      startDay: startDay,
      endYear: endYear,
      endMonth: endMonth,
      endDay: endDay,
      symbol: symbol,
      list: list,
      market: market,
      type: type,
      conditionals: conditions
    };

    
    try {
      const response = await fetch(`${process.env.REACT_APP_RUN_SCANNER_API}`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.message && data.scannerId && data.scannerName) {
        handleInsert(data.scannerId, data.scannerName);
      }      
    } catch (error) {
      console.error('There was an error running the scanne', error);
    }


  };

  return (
    <div className="chatbar-container">


      <div className="chatbar-header">
        <div className="titletext">
        Scanner Creator
        </div>
        <div className="chatbar-run">
        <button type="button" onClick={clearScanner}>Create New Scanner</button>
        </div>
        <div className="chatbar-run">
        <button type="button" onClick={handleRunClick}>Save & Run</button>
        </div>

      </div>


      <div className="scrollable-content">
        


      <div style={{paddingTop: '5px'}}>
        {/*Remeber these labels can be to the left or top depending on context, and available screen real estate, and still not made for dynamic screen sizes*/}
        <div className='details-row'>
          <div>Scanner Name:</div>
          <input type="text" className="indicator-input" placeholder="Scanner Name" value={scannerName} onChange={(e) => setScannerName(e.target.value)}/>
        </div>        

        <div className="details-row">
          <div>Start Date:</div>
            <div className='date-select-container'>
              <DateSelector year={startYear} month={startMonth} day={startDay} onYearChange={setStartYear} onMonthChange={setStartMonth} onDayChange={setStartDay}/>
            </div>
        </div>

        <div className="details-row">        
          <div>End Date:</div>
          <div className='date-select-container'>
            <DateSelector year={endYear} month={endMonth} day={endDay} onYearChange={setEndYear} onMonthChange={setEndMonth} onDayChange={setEndDay}/>
          </div>
        </div>

        <div className="details-row">
          <div>Market:</div>
          <select className="market-dropdown" value={market} onChange={(e) => setMarket(e.target.value)}>
            <option value="" disabled hidden>Select Market</option>
            <option value="Stock">Stock</option>
            <option value="Forex">Forex</option>
            <option value="Crypto">Crypto</option>
            <option value="Futures">Futures</option>
          </select>
        </div>

        <div className="details-row">
          <div>Scanner Type:</div>
          <select className="market-dropdown" value={type} onChange={(e) => setType(e.target.value)}>
            <option value="" disabled hidden>Select Type:</option>
            <option value="all">All</option>
            <option value="list">List</option>
            <option value="symbol">Symbol</option>
          </select>
        </div>


        {type === 'symbol' && (
          <div className = "details-row">
          <div>Symbol:</div>
          <input type="text" className="indicator-input" placeholder="Symbol" value={symbol} onChange={(e) => setSymbol(e.target.value)}/>
          </div>
        )}

        {type === 'list' && (
          <>
          <p style={{ marginTop: '4px', marginBottom: '0px' , fontStyle: 'italic', textAlign: 'center'}}>Enter a list of symbols separated by commas</p>
          <div className = "details-row">
          <div>Symbol List:</div>
          <input type="text" className="indicator-input" placeholder="Symbol List" value={list} onChange={(e) => setList(e.target.value)}/>          
          </div>
          </>
        )}        

        <div className="details-row">
          <div>Conditions:</div>
          <button className='edit-conditions-button' onClick={toggleEntryDetails}>Edit Conditions</button>
        </div>


        <div>
          <AdvancedPopup
            title="Scanner Conditions"
            trigger={showEntryDetails}
            setTrigger={setEntryShowDetails}
            conditions={conditions}
            setConditions={setConditions}
          />
        </div>


      </div>
      </div>
    </div>
  );
}

export default ChatBar;
