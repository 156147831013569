import React, { useState, useEffect } from 'react';
import './innerbar.css'; 

function InnerBar({ items, setItems, selectedItem, selectedListId, onItemSelect, itemType, endpoint, queryParamName }) {

  const [showTradeDetails, setTradeShowDetails] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [allTradesOnOneChart, setAllTradesOnOneChart] = useState(false);
  const [listEachTrade, setListEachTrade] = useState(true);
  //this may be repeated code, because all trades is already stored in items, but its in parent element (for equity curve). This is easier to read though
  const [allTrades, setAllTrades] = useState([]);

  useEffect(() => {

    setDropdownOpen(false);
    setAllTradesOnOneChart(false);
    setListEachTrade(true);
    setTradeShowDetails(false);
    setAllTrades([]);

    if (selectedListId != null) {
      
      const fetchItemsForList = async () => {
        try {  

          const response = await fetch(`${endpoint}?${queryParamName}=${selectedListId}`);
          const data = await response.json();

          const formattedTrades = data.map(trade => ({
            ...trade,
            date: trade.date.split(' ').slice(0, 4).join(' '),
            endDate: trade.end_date.split(' ').slice(0, 4).join(' '),
            startTime: trade.start_time,
            endTime: trade.end_time
          }));
          
          setItems(formattedTrades);
          setAllTrades(formattedTrades);
          if (formattedTrades.length > 0) {
            onItemSelect(formattedTrades[0]);
          }          
        } catch (error) {
          console.error('Failed to fetch items:', error);
        }
      };

      fetchItemsForList();
    } else {
      setItems([]);
    }
  }, [selectedListId]);
  

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  
  const handleAllTradesChange = (event) => {
    const isChecked = event.target.checked;
    setAllTradesOnOneChart(isChecked);
    
    if (isChecked) {
      setListEachTrade(false); 
      onItemSelect(allTrades);
    }
  };

  const handleListEachTradeChange = (event) => {
    const isChecked = event.target.checked;
    setListEachTrade(isChecked);
    
    if (isChecked) {
      setAllTradesOnOneChart(false); 
      onItemSelect(allTrades[0]);
    }
  };

  const handleItemClick = async(index, trade) => {
    if (selectedItem && selectedItem.completetradeID !== trade.completetradeID) {
      setTradeShowDetails(false);
      onItemSelect(trade);
    }
  };  

  const toggleTradeDetails = () => {
    setTradeShowDetails(!showTradeDetails);
  };

  const handleEntryChartClick = (index, trade) => {
    const updatedTrade = { ...trade, chartType: 'EntryOnly' };
    onItemSelect(updatedTrade);
  };

  const handleExitChartClick = async(index, trade) => {
    const updatedTrade = { ...trade, chartType: 'ExitOnly' };
    onItemSelect(updatedTrade);
  };


  //No hours, minutes, seconds for now, this should be included in the backend API response
  const calculateDuration = (startDate, endDate) => {

    const start = new Date(startDate);
    const end = new Date(endDate);
    const diff = end - start;

    const seconds = Math.floor(diff / 1000) % 60;
    const minutes = Math.floor(diff / 60000) % 60;
    const hours = Math.floor(diff / 3600000) % 24;
    const days = Math.floor(diff / 86400000);

    return `${days}D ${hours}H ${minutes}M ${seconds}S`;
  };

  
  return (
    <div className="whole_container">

      <div className="tradebar-title">
        <button onClick={toggleDropdown} className='toggle-button'>
          <span className="dropdown-icon">{isDropdownOpen ? '▼' : '▶'}</span> 
        </button>
        {itemType}
      </div>

      {isDropdownOpen && (
        <div className="dropdown-menu pop-out">
          <label>
            <input
              type="checkbox"
              checked={allTradesOnOneChart}
              onChange={handleAllTradesChange}
            />{' '}
            All trades on one chart
          </label>
          <label>
            <input
              type="checkbox"
              checked={listEachTrade}
              onChange={handleListEachTradeChange}
            />{' '}
            List each trade
          </label>
        </div>
      )}

      
      
      {listEachTrade && (
      <div className="tradebar-container">
          {items.length > 0 && items.map((item, index) => (
              
              <div 
                key={item.completetradeID} 
                id={`trade-${item.completetradeID}`}  
                className={`tradebar-item ${selectedItem && item && selectedItem.completetradeID === item.completetradeID ? 'highlight' : ''}`} 
                onClick={() => handleItemClick(index, item)}
              >
                  <div className='top-trade-details'>
                    
                    <div> 
                      <button onClick={toggleTradeDetails} className={`toggle-button ${showTradeDetails ? 'expanded' : 'collapsed'}`}>
                        <span className="arrow-icon2">{showTradeDetails && selectedItem.completetradeID === item.completetradeID  ? '▼' : '▶'}</span>
                      </button>
                    </div>

                
                    <div>
                      <div className="symbol">{item.symbol}</div>
                      <div className="date">{item.date}</div>
                      {item.pnl != undefined && (
                      <div className={`pnl ${item.pnl >= 0 ? 'positive' : 'negative'}`}>
                        ${Number(item.pnl).toFixed(2)}
                      </div>
                      )}
                    </div>
                  
                  </div>
                  
                  {selectedItem && selectedItem.completetradeID === item.completetradeID &&  showTradeDetails && (
                    <div className='bottom-trade-details'>
                      
                      <div className="trade-chart-details">
                        <p>Entry Price: {item.trades[0].price}</p>
                        <p className='underline-text' onClick={() => handleEntryChartClick(index, item)}>View Chart</p>
                      </div>
                      <p>Entry Date: {item.date}</p>
                      <div className="trade-chart-details">
                        <p>Exit Price: {item.trades[1].price}</p>
                        <p className='underline-text' onClick={() => handleExitChartClick(index, item)}>View Chart</p>
                      </div>
                      <p>Exit Date: {item.endDate}</p>
                      <p>Duration: {calculateDuration(item.date, item.endDate)}</p>
                    
                    </div>
                  )}
                
              
              </div>
          
          ))}
      </div>
      )}

      {allTradesOnOneChart && (
        <div className="tradebar-container">
              <div className={`tradebar-item highlight`}>
                    <div>
                      <div className="symbol">All trades</div>
                      <div className="date">-</div>
                    </div>
              </div>
        </div>
      )}

    </div>
  );
}

export default InnerBar;
