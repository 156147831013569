import React, { useState } from 'react';
import './advancedpopup.css';
import ConditionRow from '../conditionRow/conditionRow';
import getRandomColor from '../helpers/randomColor';


const AdvancedPopup = ({ title, trigger, setTrigger, conditions, setConditions}) => {
    
    const leftItems = ['AND', 'OR', 'GROUP', 'CONDITION'];


    const updateCondition = (path, updatedCondition) => {
      
      let newItems = JSON.parse(JSON.stringify(conditions));
      
      const updateNestedItem = (items, path, updatedCondition) => {
          if (path.length === 1) {
              items[path[0]] = { ...items[path[0]], ...updatedCondition };
          } else {
              updateNestedItem(items[path[0]].items, path.slice(1), updatedCondition);
          }
      };
      
      updateNestedItem(newItems, path, updatedCondition);
      
      setConditions(newItems);
    };


    const handleDelete = (path) => {
        let newItems = JSON.parse(JSON.stringify(conditions));
    
        const deleteNestedItem = (items, path) => {
            if (path.length === 1) {
                items.splice(path[0], 1);
            } else {
                deleteNestedItem(items[path[0]].items, path.slice(1));
            }
        };
    
        deleteNestedItem(newItems, path);
    
        setConditions(newItems);
    };


    const handleDragStart = (event, itemType, itemId = null) => {
        const itemData = JSON.stringify({ type: itemType, id: itemId });
        event.dataTransfer.setData('text', itemData);
    };


    const handleDrop = (event, path = null, insertIndex = null) => {
        event.preventDefault();
        event.stopPropagation();
    
        const droppedItemData = JSON.parse(event.dataTransfer.getData('text'));
        const { type: droppedItemType, id: droppedItemId } = droppedItemData;
        
        let newItems = JSON.parse(JSON.stringify(conditions));

        const insertItem = (array, index) => {
            const newItem = {type: droppedItemType, id: droppedItemId, items: droppedItemType === 'GROUP' ? [] : undefined, color: droppedItemType === 'GROUP' ? getRandomColor() : undefined};
            
            if (index !== null) {
                array.splice(index, 0, newItem);
            } else {
                array.push(newItem);
            }
        };

        const insertNestedItem = (theItems, path, insertIndex) => {
            if (path.length === 0) {
                insertItem(theItems, insertIndex);
            } else {
                const [currentIndex, ...restPath] = path;
                if (restPath.length > 0 && theItems[currentIndex].type === 'GROUP') {
                    insertNestedItem(theItems[currentIndex].items, restPath, insertIndex);
                } else {
                    if (theItems[currentIndex].type === 'GROUP') {
                        insertItem(theItems[currentIndex].items, insertIndex);
                    } else {
                        insertItem(theItems, currentIndex);
                    }
                }
            }
        };
    
        if (path) {
            insertNestedItem(newItems, path, insertIndex);
        } else {
            insertItem(newItems, insertIndex);
        }
    
        setConditions(newItems);
    };
    

    const handleDragOver = (event) => {
        event.preventDefault(); 
    };

    const renderGroups = (items, path = []) => {
        return items.map((item, index) => {
            
            const currentPath = [...path, index];
            return item.type === 'GROUP' ? (
            <div 
                className="group-container"
                style={{ border: `2px solid ${item.color}` }}
                onDragOver = {handleDragOver}
                onDrop={(event) => handleDrop(event, currentPath)}    
            >

                <div className="group-title">
                    Group 
                    <button className="delete-condition" onClick={() => handleDelete(currentPath)}>Remove</button>
                </div>
                <div className="group-items">
                    {renderGroups(item.items, currentPath)} 
                </div>
            </div>
                                
        ) : (


            <div 
                key={index}
                className="item-row"
                onDragOver={handleDragOver}
                onDrop={(event) => handleDrop(event, currentPath, index)} 
            >
                {item.type === 'CONDITION' ? (
                    <ConditionRow 
                        key={item.id} 
                        conditionn={item}
                        onConditionChange={(updatedCondition) => updateCondition(currentPath, updatedCondition)}
                        onDelete={() => handleDelete(currentPath)}                            
                    />
                ) : item.type === 'AND' ? (
                    <div className="and-or-condition">AND
                        <button className="delete-condition" onClick={() => handleDelete(currentPath)}>Remove</button>
                    </div>
                ) : item.type === 'OR' ? (
                    <div className="and-or-condition">OR
                         <button className="delete-condition" onClick={() => handleDelete(currentPath)}>Remove</button>
                    </div>
                ) : null}
            </div>
        )
        
        });
    }; 
    
    
    return (trigger) ? (
        <div className="advanced-popup">
            <div className="advanced-popup-inner">
                <button className="close-btn" onClick={() => setTrigger(false)}>X</button>
                <h2>{title}</h2>

                <div className="mainContent">
                    <div className="items-box1">
                        
                        {leftItems.map((item, index) => (
                            <div 
                                key={index} 
                                draggable
                                className="item-row"
                                onDragStart={(event) => handleDragStart(event, item)}                            
                            >
                                {item}</div>
                        ))}
                    </div>
                    
                    <div className="items-box2" >
                        {renderGroups(conditions)}

                        <div
                            className="empty-drop-area"
                            onDragOver={handleDragOver} 
                            onDrop={(event) => handleDrop(event)} 
                        >
                            Drop here to add items!
                        </div>
                    
                    </div>

                </div>

                <button className="save-btn" onClick={() => setTrigger(false)}>Save</button>
            </div>
        </div>
    ) : "";
};

export default AdvancedPopup;
