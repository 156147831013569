import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { Auth0Provider } from '@auth0/auth0-react';
import App from "./App";

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);
root.render(
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri:  process.env.REACT_APP_AUTH0_REDIRECT_URI,
        audience: process.env.REACT_APP_API_AUDIENCE,
      }}
    >
      <App />
    </Auth0Provider>
  );
