import  MainChart  from './mainChart/chart';
import { version } from '../../../charting_library';
import React, { useState, useEffect } from 'react';
import ChatBar from './rightBar/rightbar';
import ListBar from '../utils/listbar/listbar';
import { fetchData } from '../utils/helpers/fetchHelp';
import { handleInsert } from '../utils/helpers/handleInsert';
import { handleDelete } from '../utils/helpers/handleDelete';
import { selectedTrade } from '../utils/tempData/selectedTrade';
import useAccessToken from '../utils/helpers/getAccessToken';


function IndicatorCreator ({showToast}) {

    const getAccessToken = useAccessToken();
    const [indicators, setIndicators] = useState([]);
    const [selectedIndicator, setSelectedIndicator] = useState(null);


    useEffect(() => {
        const fetchIndicators = async () => {
            const accessToken = await getAccessToken();
            fetchData(`${process.env.REACT_APP_INDICATORS_API}`, setIndicators, setSelectedIndicator, accessToken);
        };
        fetchIndicators();
    }, []);


    const handleDeleteIndicator = (indicatorId) => {
        handleDelete(indicatorId, indicators, setIndicators, selectedIndicator, setSelectedIndicator);
    };
  
    const handleInsertIndicator = (indicatorId, indicatorName) => {
      handleInsert(indicatorId, indicatorName, indicators, setIndicators, setSelectedIndicator);
    }  


    return (

          <>
            <div className = "tradebar" style={{width: '10%'}}>
                <ListBar
                    items={indicators}
                    onItemSelect={setSelectedIndicator}
                    handleDelete={handleDeleteIndicator}
                    selectedItem={selectedIndicator}
                    itemType="Indicators"
                    deleteEndpoint={process.env.REACT_APP_DELETE_INDICATOR_API}
                    style={{width: '100%'}}
                /> 
            </div>
            <div className="middle-section" style={{width: '70%'}}>
                <div className = "stock-chart" style={{height:'100%'}}>
                {selectedIndicator && <MainChart selectedTrade={selectedTrade} selectedIndicator={selectedIndicator}/>}
                </div>
            </div>
            <div className = "chatbar">
                <ChatBar 
                    selectedIndicator={selectedIndicator}
                    handleInsert = {handleInsertIndicator}
                    showToast = {showToast}
                />
            </div>

          </>

    );
    
}


export default IndicatorCreator;
