import React, { useState, useEffect } from 'react';
import './loadingbar.css';
import { backtestSocket } from '../../../../socket';

const LoadingBar = ({ loading, note, width, setNote, setWidth }) => {


  useEffect(() => {
    backtestSocket.on('backtest_progress_loading_bar', (data) => {
      setNote(`Progress date: ${data.date}`);
      setWidth(data.percentage);  
    });

    return () => {
      backtestSocket.off('backtest_progress_loading_bar');
    };
  }, [setNote, setWidth]);

  return (
    <div>
      {loading && (
        <div className="popup">
          <div className="loading-container">
            <div className="loading-text">Please wait while the backtest is running...</div>
            <p>{note}</p>
            <div className="loading-bar-background">
              <div className="loading-bar" style={{ width: width }}></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoadingBar;
