import "./toast_logs.css";


function ToastLogs({logs, logsRef}) {

    return (
        <div className="log-box" ref={logsRef}>
          {logs.map((log, index) => (
            <div key={index} className={`log-message toaster2--${log.type}`}>
              {log.message}
            </div>
            ))}
         </div>

    );
}

export default ToastLogs;


