import Table from '../utils/table';
import {useState, useEffect} from 'react';
//no import for .css in this file



function Portfolios() {

    const [portfolios, setPortfolios] = useState([]);


    const columns = [
        { key: 'name', header: 'Portfolio Name' },
        { key: 'broker', header: 'Broker: Account' },
        { key: 'status', header: 'Status' },
        { key: 'pnl', header: 'P&L' },
      ];

    
    const fetchPortfolios = async () => {
        console.log('Fetching portfolios');
    };

    useEffect(() => {
        fetchPortfolios();
    }, []);

    return (
    <div>
        <div className='headerBar'>
          <h1 style={{fontSize: '24px'}}> Portfolios </h1>
          <button className="addStrategyButton">Add Portfolio</button>
        </div>
        <Table
            columns = {columns}
            data = {portfolios}        
        />
        
    </div>
  );
}


export default Portfolios;