
export const fetchData = async (apiUrl, setData, setSelected, accessToken) => {
    try {
      const response = await fetch(apiUrl , {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();
      setData(data);
      if (data && data.length > 0) {
        setSelected(data[0]);
      }
    } catch (error) {
      console.error(`Failed to fetch data from ${apiUrl}:`, error);
    }
  };