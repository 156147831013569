import React, {useEffect} from 'react';
import './home.css'; 
import User from '../../images/user.svg';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Dot } from 'recharts';
import { useAuth0 } from '@auth0/auth0-react';


const Home = () => {
  
  const { user, isAuthenticated, loginWithRedirect, handleRedirectCallback } = useAuth0();

  const yMinEquity = 7000;
  
  const equityData = [
    { name: 'Day 1', Equity: 7000 },
    { name: 'Day 2', Equity: 8000 },
    { name: 'Day 3', Equity: 9000 },
    { name: 'Day 4', Equity: 10000 },
    { name: 'Day 5', Equity: 11000 },
    { name: 'Day 6', Equity: 12000 },
    { name: 'Day 7', Equity: 13000 },
    { name: 'Day 8', Equity: 12500 },
    { name: 'Day 9', Equity: 13500 },
    { name: 'Day 10', Equity: 12000 }
  ];

  const recentActivity = [
    { date: '2023-06-01', strategy: 'Strategy A', instrument: 'AAPL', result: 'Profit' },
    { date: '2023-06-02', strategy: 'Strategy B', instrument: 'TSLA', result: 'Loss' },
    { date: '2023-06-03', strategy: 'Strategy A', instrument: 'GOOGL', result: 'Profit' },
    { date: '2023-06-04', strategy: 'Strategy C', instrument: 'AMZN', result: 'Profit' },
    { date: '2023-06-05', strategy: 'Strategy D', instrument: 'NFLX', result: 'Loss' },
    { date: '2023-06-06', strategy: 'Strategy E', instrument: 'MSFT', result: 'Profit' },
    { date: '2023-06-07', strategy: 'Strategy F', instrument: 'FB', result: 'Profit' },
    { date: '2023-06-08', strategy: 'Strategy G', instrument: 'TWTR', result: 'Loss' },
    { date: '2023-06-09', strategy: 'Strategy H', instrument: 'UBER', result: 'Profit' },
    { date: '2023-06-10', strategy: 'Strategy I', instrument: 'LYFT', result: 'Loss' }
  ];



  return (
    <div className="dashboard">
      <div className='userSection'>
        <img src={User} alt="User" className="user" style={{height: '80%'}} />
        <div style={{paddingLeft: '3%'}} className='userDetails'>
        <h1 className='accountName'>{user.nickname}</h1>
        <h1 className='accountEmail'>{user.email}</h1>
        </div>
      </div>
      
      <div className='DayAccountBalance'>
        <h2>$7,325.56</h2>
        <h3>+143.37</h3>
      </div>
          
      <div className='statsAndEquitySection'>
          <div className='specificStats'>
            <p>Day Account Balance</p>
            <p>Day Profit</p>
            <p>Day Profit %</p>
            <p>Day Trades</p>
            <p>Day Wins</p>
            <p>Day Losses</p>

          </div>

        
        <div className='equityCurveSection'>

          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={equityData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis domain={[yMinEquity]}/>
              <Tooltip />
              <Legend />
              <Line 
              type="linear" 
              dataKey="Equity" 
              stroke="green" 

              activeDot={(props) => (
                <Dot {...props} r={8} style={{cursor: "pointer"}} 
                 />
              )}            
              />

            </LineChart>
          </ResponsiveContainer>

        </div>
      </div>

      <div className='recentActivity'>
        <h1>Recent Activity</h1>
        <div className="activityList">
          {recentActivity.map((activity, index) => (
            <div key={index} className="activityItem">
              <p>{activity.date} - {activity.strategy} - {activity.instrument} - {activity.result}</p>
            </div>
          ))}
        </div>
      </div>


    </div>
  );
};

export default Home;
