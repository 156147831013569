import React, { useState, useEffect } from 'react';
import ConditionRow from '../../utils/conditionRow/conditionRow';
import { v4 as uuidv4 } from 'uuid';
import '../../utils/rightBar/Rightbar.css';
import AdvancedPopup from '../../utils/advancedPopUp/advancedPopup'; 
import DateSelector from '../../utils/helpers/dateRow';
import LoadingBar from '../../utils/loadingBar/LoadingBar';
import { backtestSocket } from '../../../../socket';
import { useAuth0 } from '@auth0/auth0-react';
import useAccessToken from '../../utils/helpers/getAccessToken';


function ChatBar( {selectedStrategy, onStrategySelect, onTradeSelect, handleInsert, showToast} ) {
  
  const getAccessToken = useAccessToken();
  const {isAuthenticated, isLoading, loginWithRedirect, user} = useAuth0();
  const [EntryConditions, setEntryConditions] = useState([]);
  const [ExitConditions, setExitConditions] = useState([]);

  const [isEntryAdvancedPopupOpen, setIsEntryAdvancedPopupOpen] = useState(false);
  const [isExitAdvancedPopupOpen, setIsExitAdvancedPopupOpen] = useState(false);

  const [strategyName, setStrategyName] = useState('');
  const [market, setMarket] = useState('');
  const [startYear, setStartYear] = useState('2024');
  const [startMonth, setStartMonth] = useState('01');
  const [startDay, setStartDay] = useState('01');
  const [endYear, setEndYear] = useState('2024');
  const [endMonth, setEndMonth] = useState('01');
  const [endDay, setEndDay] = useState('01');
  const [symbol, setSymbol] = useState('');
  const [tradeSide, setTradeSide] = useState('');
  const [accountSize, setAccountSize] = useState('');
  const [positionSize, setPositionSize] = useState('');
  const [commissionType, setCommissionType] = useState('');
  const [commissionAmount, setCommissionAmount] = useState('');
  const [slippageAmount, setSlippageAmount] = useState('');

  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState('This is a small note');
  const [width, setWidth] = useState('0%');

  
  useEffect(() => {
    if (selectedStrategy != null) {
      const fetchDetailsForStrategy = async () => {
        try {  
          const response = await fetch(`${process.env.REACT_APP_GET_STRATEGY_DETAILS}?strategyId=${selectedStrategy.id}`);
          const data = await response.json();
          setStrategyName(data.strategyName);
          setSymbol(data.symbol);
          setMarket(data.market);
          setStartYear(data.start_year);
          setStartMonth(data.start_month);
          setStartDay(data.start_day);
          setEndYear(data.end_year);
          setEndMonth(data.end_month);
          setEndDay(data.end_day);
          setTradeSide(data.trade_side);        
          setAccountSize(data.account_size);
          setPositionSize(data.position_size);
          setCommissionType(data.commission_type);
          setCommissionAmount(data.commission_amount);
          setSlippageAmount(data.slippage);
                
          const loadedEntryConditions = data.entry_conditionals.map(condition => ({
            ...condition,
          }));
          
          setEntryConditions(loadedEntryConditions);

          const loadedExitConditions = data.price_target_conditionals.map(condition => ({
            ...condition,
          }));
          

          setExitConditions(loadedExitConditions);

        } catch (error) {
          console.error('Failed to fetch strategy details:', error);
        }
      };

      fetchDetailsForStrategy();
    } else {
      setStrategyName('');
      setMarket('');
      setStartYear('2024');
      setStartMonth('01');
      setStartDay('01');
      setEndYear('2024');
      setEndMonth('01');
      setEndDay('01');
      setSymbol('');
      setTradeSide('');
      setAccountSize('');
      setPositionSize('');
      setCommissionType('');
      setCommissionAmount('');
      setSlippageAmount('');
      setEntryConditions([]);
      setExitConditions([]);
    }
  }, [selectedStrategy]);


  const editEntryConditions = () => {
    setIsEntryAdvancedPopupOpen(true)
  };

  const editExitConditions = () => {
    setIsExitAdvancedPopupOpen(true)
  };

  
  const clearStrategy = () => {
    setEntryConditions([]);
    setExitConditions([]);
    setStrategyName('');
    setMarket('');
    setStartYear('2024');
    setStartMonth('01');
    setStartDay('01');
    setEndYear('2024');
    setEndMonth('01');
    setEndDay('01');
    setSymbol('');
    setTradeSide('');
    setAccountSize('');
    setPositionSize('');
    setCommissionType('');
    setCommissionAmount('');
    setSlippageAmount('');
    onStrategySelect(null);
    onTradeSelect(null);
  };
  
  const handleStartLoading = () => {
    setLoading(true);
    setNote('Running backtest...');
    setWidth('0%');
  };

  const handleBacktestComplete = () => {
    setLoading(false);
    setNote('Backtest completed.');
    setWidth('100%');
  };


  const handleRunClick = async () => {

    if (!strategyName) {
      showToast('Please enter a strategy name', 'error');
      return;
    }

    if (!symbol) {
      showToast('Please enter a symbol', 'error');
      return;
    }
    
    //make sure dates are being set correct because they were moved to own component
    const payload = {
      strategyName: strategyName,
      market: market,
      startYear: startYear,
      startMonth: startMonth,
      startDay: startDay,
      endYear: endYear,
      endMonth: endMonth,
      endDay: endDay,
      symbol: symbol,
      tradeSide: tradeSide,
      accountSize: accountSize,
      positionSize: positionSize,
      commissionType: commissionType,
      commissionAmount: commissionAmount,
      slippageAmount: slippageAmount,
      EntryConditionals: EntryConditions,
      PriceTargetConditionals: ExitConditions,
    };

    //this will not be used in production, but for testing purposes. This can be done once at the start of the application
    const dataToSend = {
      UserID: user.sub, 
    };

    backtestSocket.emit('loading_bar_needs', dataToSend);

    handleStartLoading();

    const accessToken = await getAccessToken();
    try {
      const response = await fetch(`${process.env.REACT_APP_RUN_STRATEGY_API}`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.message && data.strategyId && data.strategyName) {
        handleInsert(data.strategyId, data.strategyName);
      }      
    } catch (error) {
      console.error('There was an error running the strategy', error);
    } finally {
      handleBacktestComplete();
    }

  };

  return (
    <div className="chatbar-container">


      <div className="chatbar-header">
        <div className="titletext">
        Strategy Builder
        </div>
        <div className="chatbar-run">
        <button type="button" onClick={clearStrategy}>Create New Strategy</button>
        </div>
        <div className="chatbar-run">
        <button type="button" onClick={handleRunClick}>Save & Run</button>
        </div>

      </div>


      <div className="scrollable-content">
        

        <div style={{paddingTop: '5px'}}>
        {/*Remeber these labels can be to the left or top depending on context, and available screen real estate, and still not made for dynamic screen sizes*/}
        <div className='details-row'>
          <div>Strategy Name:</div>
          <input type="text" className="indicator-input" placeholder="Strategy Name" value={strategyName} onChange={(e) => setStrategyName(e.target.value)}/>
        </div>
        
        <div className='details-row'>
          <div>Market:</div>
          <select className="market-dropdown" value={market} onChange={(e) => setMarket(e.target.value)}>
            <option value="" disabled hidden>Select Market</option>
            <option value="Stock">Stock</option>
            <option value="Forex">Forex</option>
            <option value="Crypto">Crypto</option>
            <option value="Futures">Futures</option>
            <option value="Stock Options">Stock Options</option>
          </select>
        </div>
        
        <div className="details-row">
          <div>Start Date:</div>
            <div className='date-select-container'>
              <DateSelector year={startYear} month={startMonth} day={startDay} onYearChange={setStartYear} onMonthChange={setStartMonth} onDayChange={setStartDay}/>
            </div>
        </div>

        <div className="details-row">        
          <div>End Date:</div>
          <div className='date-select-container'>
            <DateSelector year={endYear} month={endMonth} day={endDay} onYearChange={setEndYear} onMonthChange={setEndMonth} onDayChange={setEndDay}/>
          </div>
        </div>

        <div className='details-row'>
          <div>Symbol:</div>
          <input type="text" className="indicator-input" placeholder="Symbol" value={symbol} onChange={(e) => setSymbol(e.target.value)}/>
        </div>

        <div className='details-row'>
          <div>Long/Short:</div>
          <select className="market-dropdown" value={tradeSide} onChange={(e) => setTradeSide(e.target.value)}>
            <option value="" disabled hidden>Select Trade Side</option>
            <option value="long">Long</option>
            <option value="short">Short</option>
          </select>
        </div>

        <div className='details-row'>        
          <div>Account Size:</div>
          <input type="text" className="indicator-input" placeholder="Account Size" value={accountSize} onChange={(e) => setAccountSize(e.target.value)}/>
        </div>

        {/* Put a parethesis after position size with shares/futures/contracts depending on the market */}
        <div className='details-row'>        
          <div>Position Size:</div>
          <input type="text" className="indicator-input" placeholder="Position Size" value={positionSize} onChange={(e) => setPositionSize(e.target.value)}/>
        </div>

        <div className='details-row'>
          <div>Commission Type:</div>
          <select className="market-dropdown" value={commissionType} onChange={(e) => setCommissionType(e.target.value)}>
            <option value="" disabled hidden>Select Commission Type</option>
            <option value="pertrade">per trade</option>
            <option value="pershare">per share</option>
          </select>
        </div>

        <div className='details-row'>
          <div>Commission Amount:</div>
          <input type="text" className="indicator-input" placeholder="Commission Amount" value={commissionAmount} onChange={(e) => setCommissionAmount(e.target.value)}/>
        </div>

        <div className='details-row'>
          <div>Slippage Amount:</div>
          <input type="text" className="indicator-input" placeholder="Slippage Amount" value={slippageAmount} onChange={(e) => setSlippageAmount(e.target.value)}/>
        </div>
        <div className="details-row">
            <div>Entry Conditions:</div>
            <button className='edit-conditions-button' onClick={editEntryConditions}>Edit Conditions</button>
        </div>

        <div>
            <AdvancedPopup 
              title="Entry Conditions"
              trigger={isEntryAdvancedPopupOpen} 
              setTrigger={setIsEntryAdvancedPopupOpen}
              conditions={EntryConditions}
              setConditions={setEntryConditions}
            />
        </div>
                
        <div className="details-row">
          <div>Exit Conditions:</div>
          <button className='edit-conditions-button' onClick={editExitConditions}>Edit Conditions</button>
        </div>

        <div>
            <AdvancedPopup 
              title="Exit Conditions"
              trigger={isExitAdvancedPopupOpen} 
              setTrigger={setIsExitAdvancedPopupOpen}
              conditions={ExitConditions}
              setConditions={setExitConditions}
            />
        </div>

        <LoadingBar loading={loading} note={note} width={width} setNote={setNote} setWidth={setWidth}/>

        </div>

      </div>
    
    </div>
  );
}

export default ChatBar;
