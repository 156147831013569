import Table from '../utils/table';
import {useState, useEffect} from 'react';




function Trades() {

    const [trades, setTrades] = useState([]);

    const columns = [
        { key: 'account', header: 'Account' },
        { key: 'broker', header: 'Broker' },
        { key: 'symbol', header: 'Symbol' },
        { key: 'type', header: 'Type' },
        { key: 'price', header: 'Price' },
        { key: 'side', header: 'Side' },
        { key: 'quantity', header: 'Quantity' },
        { key: 'time', header: 'Time' }
    ];

    
    const fetchTrades = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_GET_TRADES}`);
            const data = await response.json();
            setTrades(data);
          } catch (error) {
            console.error('Failed to fetch trades:', error);
          }
    };

    useEffect(() => {
        fetchTrades();
    }, []);

    return (
    <div>
        <h1 style={{paddingLeft: '0.5%', fontSize: '24px'}}> Trades (today only) </h1>
        <Table
            columns = {columns}
            data = {trades}        
        />
        
    </div>
  );
}


export default Trades;