import  MainChart  from '../../backtesting/strategyBuilder/mainChart/chart';
import { version } from '../../../charting_library';
import EquityCurve from '../../backtesting/portfolioBuilder/datavisuals/equityCurve';
import StatsPanel from '../../backtesting/portfolioBuilder/statspanel/statsPanel';
import React, { useState, useEffect } from 'react';
import ListBar from '../../backtesting/utils/listbar/listbar';
import InnerBar from '../../backtesting/utils/innerbar/innerbar';
import DetailsBar from '../utils/detailsBar'
import { fetchData } from '../../backtesting/utils/helpers/fetchHelp';
import { handleInsert } from '../../backtesting/utils/helpers/handleInsert';
import { handleDelete } from '../../backtesting/utils/helpers/handleDelete';
import useAccessToken from '../../backtesting/utils/helpers/getAccessToken';



function ReviewAccounts ({showToast}) {

    const getAccessToken = useAccessToken();
    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [trades, setTrades] = useState([]);
    const [selectedTrade, setSelectedTrade] = useState(null);


    useEffect(() => {
      const fetchAccounts = async () => {
        const accessToken = await getAccessToken();
        fetchData(`${process.env.REACT_APP_ACCOUNTS_API}`, setAccounts, setSelectedAccount, accessToken);
      };
      fetchAccounts();
    }, []);

    const handleDeleteAccount = (accountId) => {
        handleDelete(accountId, accounts, setAccounts, selectedAccount, setSelectedAccount);
    };
  
    const handleInsertAccount = (accountId, accountName) => {
      handleInsert(accountId, accountName, accounts, setAccounts, setSelectedAccount);
    }  

    const handleTradeSelect = (trade) => {
        setSelectedTrade(trade);
        const tradeElement = document.getElementById(`trade-${trade.completetradeID}`);
        if (tradeElement) {
          tradeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };	  

    return (
        <>
        <div className = "tradebar">
          <ListBar
            items={accounts}
            onItemSelect={setSelectedAccount}
            handleDelete={handleDeleteAccount}
            selectedItem={selectedAccount}
            itemType="Accounts"
            deleteEndpoint={process.env.REACT_APP_DELETE_ACCOUNT_API}
          /> 
          
          <InnerBar
              items={trades}
              setItems={setTrades}
              selectedItem={selectedTrade}
              selectedListId={selectedAccount ? selectedAccount.id : null}
              onItemSelect={setSelectedTrade}
              itemType="Trades"
              endpoint={process.env.REACT_APP_ACCOUNT_LIST_API}
              queryParamName="accountId"
          />




        </div>
        <div className="middle-section">
        <div className = "stock-chart">

        {/* this part of code has not been tested yet, specific is the selectedPortfolio */}
        {selectedTrade && <MainChart selectedTrade={selectedTrade} selectedStrategy={selectedAccount}/>}
          </div>
          <div className="strategy-section">
          <div className = "equity-curve-whole">
          <div className = "equity-curve-name">
          </div>
          <div className = "equity-curve">
          <EquityCurve
               trades={trades}
               selectedStrategyId={selectedAccount ? selectedAccount.id : null}
               onTradeSelect = {handleTradeSelect}
          />
          </div>
          </div>
          <div className = "strategy-stats">
              <StatsPanel
                  selectedStrategyId={selectedAccount ? selectedAccount.id : null}
              />
          </div>
          </div>
        </div>


        <div className = "chatbar">
            <DetailsBar/>
        </div>


  </>


    );
    
}


export default ReviewAccounts;
