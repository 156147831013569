import  MainChart  from '../strategyBuilder/mainChart/chart';
import { version } from '../../../charting_library';
import React, { useState, useEffect } from 'react';
import ListBar from '../utils/listbar/listbar';
import InnerBar from '../utils/innerbar/innerbar';
import ChatBar from './rightBar/rightbar';
import { fetchData } from '../utils/helpers/fetchHelp';
import { handleInsert } from '../utils/helpers/handleInsert';
import { handleDelete } from '../utils/helpers/handleDelete';
import { selectedTrade } from '../utils/tempData/selectedTrade';
import { selectedStrategy } from '../utils/tempData/selectedStrategy';
import useAccessToken from '../utils/helpers/getAccessToken';


function Scanner ({showToast}) {

    const getAccessToken = useAccessToken();
    const [scanners, setScanners] = useState([]);
    const [selectedScanner, setSelectedScanner] = useState(null);
    const [hits, setHits] = useState([]);
    const [selectedHit, setSelectedHit] = useState(null);



    useEffect(() => {
      const fetchScanners = async () => {
        const accessToken = await getAccessToken();
        fetchData(`${process.env.REACT_APP_SCANNERS_API}`, setScanners, setSelectedScanner, accessToken);
      };
    }, []);

    
    const handleDeleteScanner = (scannerId) => {
        handleDelete(scannerId, scanners, setScanners, selectedScanner, setSelectedScanner);
    };

  
    const handleInsertScanner = (scannerId, scannerName) => {
      handleInsert(scannerId, scannerName, scanners, setScanners, setSelectedScanner);
    };
  


    return (

          <>
            <div className = "tradebar" >
                <ListBar
                    items={scanners}
                    onItemSelect={setSelectedScanner}
                    handleDelete={handleDeleteScanner}
                    selectedItem={selectedScanner}
                    itemType="Scanners"
                    deleteEndpoint={process.env.REACT_APP_DELETE_SCANNER_API}
                /> 
                <InnerBar
                    items={hits}
                    setItems={setHits}
                    selectedItem={selectedHit}
                    selectedListId={selectedScanner ? selectedScanner.id : null}
                    onItemSelect={setSelectedHit}
                    itemType="Hits"
                    endpoint={process.env.REACT_APP_SCANNERS_LIST_API}
                    queryParamName="scannerId"
                />
            </div>
            <div className="middle-section" >
                <div className = "stock-chart" style={{height:'100%'}}>
                  {selectedHit && <MainChart selectedTrade={selectedHit} selectedStrategy={selectedStrategy}/>}
                </div>
            </div>
            <div className = "chatbar">
                <ChatBar 
                    selectedScanner={selectedScanner}
                    handleInsert = {handleInsertScanner}
                    showToast = {showToast}
                />
            </div>

          </>

    );
    
}


export default Scanner;
