import React, { useState, useEffect, useRef } from 'react';
import './navBar.css'; 
import { Link, useLocation } from 'react-router-dom';
import ToastLogs from '../toaster/toast_logs';
import Logo from '../../images/main_2.png';
import User from '../../images/user.svg';
import { useAuth0 } from '@auth0/auth0-react';
import ProfileDropdown from '../profileDropdown/ProfileDropdown';


function NavigationBar({ logs }) {

  const { user, isAuthenticated, loginWithRedirect, handleRedirectCallback } = useAuth0();
  const [showLogs, setShowLogs] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation(); 
  const dropdownRef = useRef(null);
  const logsRef = useRef(null);

  const handleLogsClick = () => {
    setShowLogs(!showLogs);
  }
  
  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }

        if (logsRef.current && !logsRef.current.contains(event.target)) {
              setShowLogs(false);
        }

    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="nav-menu">
        <img src={Logo} alt="Logo" className="logo" style={{ height: '35px', width: 'auto', paddingLeft: '10px' }} />
        <Link to="/home" className={`nav-item ${location.pathname.startsWith('/home') ? 'nav-item-selected' : ''}`}>Home</Link>
        <Link to="/backtesting/strategybuilder" className={`nav-item ${location.pathname.startsWith('/backtesting') ? 'nav-item-selected' : ''}`}>Backtesting</Link>
        <Link to="/livetrading" className={`nav-item ${location.pathname.startsWith('/livetrading') ? 'nav-item-selected' : ''}`}>Livetrading</Link>
      </div>
      <div className="nav-menu-right">
        
        <div className="userDiv" onClick={toggleDropdown} ref={dropdownRef}>
          <div className="nav-item">{user.nickname}</div>
          <img src={User} alt="User" className="user" style={{ height: '25px', width: 'auto', paddingRight: '10px' }} />
        </div>
        {showDropdown && ( <ProfileDropdown dropdownRef={dropdownRef}/>)}

        <Link className="nav-item" onClick={handleLogsClick} ref={logsRef}>Logs</Link>
        {showLogs && <ToastLogs logs={logs} logsRef={logsRef}/>}
      </div>
    </nav>
  );
}

export default NavigationBar;