import './liveCore.css'; 
import React, { useEffect } from 'react';
import { Routes, Route, Link, useLocation, Navigate } from 'react-router-dom';
import StrategyDeployments from './deployments/strategies';
import Positions from './overview/positions';
import Accounts from './overview/accounts';
import Orders from './overview/orders';
import Trades from './overview/trades';
import Portfolios from './deployments/portfolios';
import Scanners from './deployments/scanners';
import ReviewAccounts from './results/accounts';
import ReviewStrategies from './results/strategies';
import ReviewPortfolios from './results/portfolios';



const LiveCore = ({showToast}) => {

    const location = useLocation(); 


    const isReviewAccountsRoute = location.pathname === '/livetrading/review-accounts';
    const isReviewStrategiesRoute = location.pathname === '/livetrading/review-strategies';
    const isReviewPortfoliosRoute = location.pathname === '/livetrading/review-portfolios';


    return (
        <div className='liveTradingPage'>
          <div className="sidebar-live">
            <p className="section-title disabled">Overview</p>
            <Link to="positions" className={`nav-item ${location.pathname === '/livetrading/positions' ? 'selected' : ''}`}>Positions</Link>
            <Link to="accounts" className={`nav-item ${location.pathname === '/livetrading/accounts' ? 'selected' : ''}`}>Accounts</Link>
            <Link to="orders" className={`nav-item ${location.pathname === '/livetrading/orders' ? 'selected' : ''}`}>Orders</Link>
            <Link to="trades" className={`nav-item ${location.pathname === '/livetrading/trades' ? 'selected' : ''}`}>Trades</Link>
    
            <p className="section-title disabled">Deployments</p>
            <Link to="strategies" className={`nav-item ${location.pathname === '/livetrading/strategies' ? 'selected' : ''}`}>Strategies</Link>
            <Link to="portfolios" className={`nav-item ${location.pathname === '/livetrading/portfolios' ? 'selected' : ''}`}>Portfolios</Link>
            <Link to="scanners" className={`nav-item ${location.pathname === '/livetrading/scanners' ? 'selected' : ''}`}>Scanners</Link>
    
            <p className="section-title disabled">Review Results</p>
            <Link to="review-accounts" className={`nav-item ${location.pathname === '/livetrading/review-accounts' ? 'selected' : ''}`}>Accounts</Link>
            <Link to="review-strategies" className={`nav-item ${location.pathname === '/livetrading/review-strategies' ? 'selected' : ''}`}>Strategies</Link>
            <Link to="review-portfolios" className={`nav-item ${location.pathname === '/livetrading/review-portfolios' ? 'selected' : ''}`}>Portfolios</Link>
          </div>
    
          <div className={`main-content ${(isReviewAccountsRoute || isReviewStrategiesRoute || isReviewPortfoliosRoute) ? 'flex-display' : ''}`}>
            <Routes>
              <Route path="/" element={<Navigate to="positions" />}/>
              <Route path="positions" element={<Positions/>} />
              <Route path="accounts" element={<Accounts showToast={showToast}/>} />
              <Route path="orders" element={<Orders/>} />
              <Route path="trades" element={<Trades/>} />
              <Route path="strategies" element={<StrategyDeployments />} /> 
              <Route path="portfolios" element={<Portfolios/>} />
              <Route path="scanners" element={<Scanners/>} />
              <Route path="review-accounts" element={<ReviewAccounts/>} />
              <Route path="review-strategies" element={<ReviewStrategies/>} />
              <Route path="review-portfolios" element={<ReviewPortfolios/>} />
            </Routes>
          </div>
        </div>
      );
    };

export default LiveCore;