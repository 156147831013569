import React, { useState, useEffect } from 'react';
import '../../utils/conditionRow/conditionRow.css';
import Value from '../../utils/conditionRow/value';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';
import { options } from '../../utils/helpers/options';
import { customStyles } from '../../utils/helpers/styles';
import { setNestedValue } from '../../utils/helpers/createDictionaryHelper'

const ConditionRow = ({ id, conditionn, onConditionChange,  onDelete }) => {
  const [leftOperations, setLeftOperations] = useState(conditionn?.Value1?.operations || []);

  const [condition, setCondition] = useState(conditionn);

  const updateLeftOperation = (index, updatedLeftOperation) => {
    const updatedOperations = leftOperations.map((op, i) => 
    i === index ? updatedLeftOperation : op);
    setLeftOperations(updatedOperations);
    handleInputChange('Value1.operations', updatedOperations);
  };


  const handleInputChange = (name, value) => {
    const newCondition = setNestedValue({ ...condition }, name, value);
    setCondition(newCondition);
    console.log(condition);
    onConditionChange(newCondition);
  };

  const addLeftOperation = () => {
    const newOperation = { id: uuidv4(), comparison: 'Add', valuee: '' };
    setLeftOperations([...leftOperations, newOperation]);
  };


  const deleteLeftOperation = (operationIndex) => {
    const updatedOperations = leftOperations.filter((_, index) => index !== operationIndex);
    setLeftOperations(updatedOperations);
    handleInputChange('Value1.operations', updatedOperations);
  };

  
  const handleSelectChange = (selectedOption) => {
    handleInputChange('Value1.type.name', selectedOption.value);
  };


  
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <div className="chatbar-condition-row">
      <div className = "chatbar-condition-row-values" >
      <button onClick={toggleDetails} className="detail-toggle-btn">+</button>
      <Select
        styles={customStyles}
        className="indicator-dropdown-select-portfolio"
        value={options.find(option => option.value === (condition?.Value1?.type?.name ?? ''))}
        onChange={handleSelectChange}
        options={options}
        isSearchable={true}
      />
      <button onClick={() => onDelete(id)} className="delete-condition">
        Remove
      </button>
      </div>
      {showDetails && (
        <div className="indicator-details">
          
          <Value 
            order = "Value1"
            value={condition.Value1}
            handleInputChange={handleInputChange} 
            operations = {leftOperations}
            deleteOperation = {deleteLeftOperation}
            updateOperation = {updateLeftOperation}
            addOperation = {addLeftOperation}
          />

        </div>

      )}
    </div>    
  );
};

export default ConditionRow;
