import React, { useEffect, useRef, useState,useMemo,  memo } from 'react';
import './chart.css';
import { widget } from '../../../charting_library';
import { createDataFeed  } from '../utils/tradingViewUtils';



function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}



const MainChart = memo(({detailLive}) => {


	const chartContainerRef = useRef();
	const tvWidgetRef = useRef(null);
	const historicalData = useRef(null);
	const currentBar = useRef(null);
	const indicatorData = useRef(null);


	useEffect(() => {

		console.log('detailLive',detailLive)

		const fetchIndicatorDetails = async () => {
			console.log('fetchIndicatorDetails called');
            try {  
              const response = await fetch(`${process.env.REACT_APP_GET_INDICATOR_DETAILS}?strategyId=${detailLive.id}`);
              const data = await response.json();
			  indicatorData.current = data;
			  console.log(indicatorData.current);
            } catch (error) {
              console.error('Failed to fetch indicator details:', error);
            }
          };


		const widgetOptions = {
			
			symbol: detailLive.ticker,	
			datafeed: createDataFeed(detailLive.id, tvWidgetRef, historicalData),
			interval: '1',																	
			timezone: "America/New_York",
			container: chartContainerRef.current,
			library_path: '/charting_library/',
			locale: getLanguageFromURL() || 'en',
			disabled_features: ['use_localstorage_for_settings'],
			enabled_features: ['study_templates'],
			charts_storage_url: 'https://saveload.tradingview.com',
			charts_storage_api_version: '1.1',
			timeframe: '1D',
			
			client_id: 'tradingview.com',
			user_id: 'public_user_id',
			fullscreen: false,
			autosize: true,
			studies_overrides: {},

			custom_indicators_getter: async (PineJS) => {
				console.log('custom_indicators_getter called');
				await fetchIndicatorDetails();
				const indicators = indicatorData.current.map(indicator_data => {
				  const metainfo = {
					_metainfoVersion: 51,
					id: `${indicator_data.name}@tv-basicstudies-1`,
					name: indicator_data.name,
					description: indicator_data.name,
					shortDescription: indicator_data.name,
					is_price_study: true,
					isCustomIndicator: true,
					format: {
					  type: "price",
					  precision: 2,
					},
					plots: indicator_data.plots.map((plot, index) => ({
					  id: `plot_${index}`,
					  type: plot.type || "line",
					})),
					defaults: {
					  styles: indicator_data.plots.reduce((styles, plot, index) => {
						styles[`plot_${index}`] = {
						  linestyle: plot.linestyle || 0,
						  linewidth: plot.linewidth || 1,
						  plottype: plot.plottype || 0,
						  trackPrice: !!plot.trackPrice,
						  transparency: plot.transparency || 0,
						  visible: plot.visible !== false,
						  color: plot.color || '#000080', 
						};
						return styles;
					  }, {}),
					  inputs: {},
					},
					styles: indicator_data.plots.reduce((styles, plot, index) => {
					  styles[`plot_${index}`] = {
						title: plot.title || `Plot ${index}`,
						histogramBase: plot.histogramBase || 0,
						joinPoints: !!plot.joinPoints,
					  };
					  return styles;
					}, {}),
					inputs: [],
				  };
			  

				  //MAIN QUESTION SO FAR WILL SEE IF UPDATES A CANDLE BEHIND WHEN NEW CLOSE DATA COMES IN AND HOW IT HANDLES THAT
				  const constructor = function () {
					this.main = function (context, inputCallback) {
					  this._context = context;
					  this._input = inputCallback;
					  const symbolTime = PineJS.Std.time(this._context);
					  if (Number.isNaN(symbolTime)) {
						return; 
					  }
					  
					  const matchingData = historicalData.current.find(item => item.t === symbolTime);
					  if (!matchingData) return;
					  
					  return indicator_data.plots.map(plot => matchingData[plot.key]);
					};
				  };
			  
				  return { name: indicator_data.name, metainfo, constructor };
				});
			  
				return Promise.resolve(indicators);
			  },

		};

		const tvWidget = new widget(widgetOptions);

		tvWidget.onChartReady(() => {
			tvWidget.headerReady().then(() => {
				


				//plot trading view ema89
				tvWidget.activeChart().createStudy('Moving Average Exponential', false, false, { length: 89 });
				
				//plot custom indicators to compare
				indicatorData.current.map(indicator_data => {
					tvWidget.activeChart().createStudy(indicator_data.name)
				});

				
				const button = tvWidget.createButton();
				button.setAttribute('title', 'Click to show a notification popup');
				button.classList.add('apply-common-tooltip');
				button.addEventListener('click', () => tvWidget.showNoticeDialog({
					title: 'Notification',
					body: 'TradingView Charting Library API works correctly',
					callback: () => {
						console.log('Noticed!');
					},
				}));

				button.innerHTML = 'Check API';
			});

		});

		tvWidgetRef.current = tvWidget;

		return () => {
			tvWidget.remove();
		};
	});

	return (
		<div
			ref={chartContainerRef}
			className={'MainChart'}
		/>
	);
});

export default MainChart;