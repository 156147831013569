import React, { useState, useEffect } from 'react';
import './backTesting.css';
import IndicatorCreator from './indicatorCreator/indicatorcreate';
import StrategyBuilder from './strategyBuilder/strategybuild';
import PortfolioBuilder from './portfolioBuilder/portfoliobuild';
import PositionSizing from './positionSizing/positionsize';
import Scanner from './scanner/scanner';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import WorkStation from './workStation/workstation';



function BackTesting({ showToast }) 
{
	const location = useLocation(); 

	const SubNavbar = () => {
	  return (
		<div className="sub-navbar">
		  <div className="sub-navbar-title">
			<Link to="strategybuilder" className={`nav-sub-item ${location.pathname === '/backtesting/strategybuilder' ? 'nav-sub-item-selected' : ''}`}>Strategies</Link>
			<Link to="portfoliobuilder" className={`nav-sub-item ${location.pathname === '/backtesting/portfoliobuilder' ? 'nav-sub-item-selected' : ''}`}>Portfolios</Link>
			<Link to="positionsizing" className={`nav-sub-item ${location.pathname === '/backtesting/positionsizing' ? 'nav-sub-item-selected' : ''}`}>Position Sizing</Link>
			<Link to="scanner" className={`nav-sub-item ${location.pathname === '/backtesting/scanner' ? 'nav-sub-item-selected' : ''}`}>Scanners</Link>
			<Link to="indicatorcreator" className={`nav-sub-item ${location.pathname === '/backtesting/indicatorcreator' ? 'nav-sub-item-selected' : ''}`}>Indicators</Link>
			{/*<Link to="workstation" className={`nav-sub-item ${location.pathname === '/backtesting/workstation' ? 'nav-sub-item-selected' : ''}`}>Work Station</Link>      Remove the components for this later      */}
		  </div>
		</div>
	  );
	};
  
	return (
	  <div className='whole-application'>
		<SubNavbar />
		<div className="application">
		  <Routes>
			<Route path="strategybuilder" element={<StrategyBuilder />} />
			<Route path="indicatorcreator" element={<IndicatorCreator />} />
			<Route path="portfoliobuilder" element={<PortfolioBuilder showToast={showToast} />} />
			<Route path="positionsizing" element={<PositionSizing showToast={showToast}/>} />
			<Route path="scanner" element={<Scanner showToast={showToast}/>} />
		  	{/*<Route path="workstation" element={<WorkStation showToast={showToast} />} />      Remove the components for this later     */}
		  </Routes>
		</div>
	  </div>
	);
  }
  
  export default BackTesting;