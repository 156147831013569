export const options = [
    { value: '', label: '-' },
    
    //Original Indicators
    { value: 'open', label: 'Open' },
    { value: 'high', label: 'High' },
    { value: 'low', label: 'Low' },
    { value: 'close', label: 'Close' },
    { value: 'volume', label: 'Volume' },
    { value: 'time', label: 'Time' },
    { value: 'date', label: 'Date' },
    { value: 'currentprice', label: 'Current Price' },
    { value: 'averageprice', label: 'Average Price' },  
    { value: 'number', label: 'Number' },
    { value: 'accountbalance', label: 'Account Balance' },


    //Overlap Studies
    { value: 'dema', label: 'DEMA' },
    { value: 'ht_trendline', label: 'Hilbert Transform - Instantaneous Trendline' },
    { value: 'kama', label: 'Kaufman Adaptive Moving Average' },
    { value: 'bbands', label: 'Bollinger Bands' },
    { value: 'ema', label: 'EMA' },
    { value: 'sma', label: 'SMA' },
    { value: 'ma', label: 'Moving average' },
    { value: 'mama', label: 'MESA Adaptive Moving Average' },
    { value: 'mavp', label: 'Moving average with variable period' },
    { value: 'midpoint', label: 'MidPoint over period' },
    { value: 'midprice', label: 'Midpoint Price over period' },
    { value: 'sar', label: 'Parabolic SAR' },
    { value: 'sarext', label: 'Parabolic SAR - Extended' },
    { value: 'sma', label: 'Simple Moving Average' },
    { value: 't3', label: 'Triple Exponential Moving Average (T3)' },
    { value: 'tema', label: 'Triple Exponential Moving Average' },
    { value: 'trima', label: 'Triangular Moving Average' },
    { value: 'wma', label: 'Weighted Moving Average' },

    //Momentm Indicators
    { value: 'adx', label: 'ADX' },
    { value: 'diplus', label: 'DI Plus' },
    { value: 'diminus', label: 'DI Minus' },
    { value: 'macd', label: 'MACD' },
    { value: 'rsi', label: 'RSI' },
    { value: 'adxr', label: 'Average Directional Movement Index Rating' },
    { value: 'apo', label: 'Absolute Price Oscillator' },
    { value: 'aroon', label: 'Aroon' },
    { value: 'aroonosc', label: 'Aroon Oscillator' },
    { value: 'bop', label: 'Balance Of Power' },
    { value: 'cci', label: 'Commodity Channel Index' },
    { value: 'cmo', label: 'Chande Momentum Oscillator' },
    { value: 'dx', label: 'Directional Movement Index' },
    { value: 'macdext', label: 'MACD with controllable MA type' },
    { value: 'macdfix', label: 'Moving Average Convergence/Divergence Fix 12/26' },
    { value: 'mfi', label: 'Money Flow Index' },
    { value: 'minus_dm', label: 'Minus Directional Movement' },
    { value: 'mom', label: 'Momentum' },
    { value: 'plus_dm', label: 'Plus Directional Movement' },
    { value: 'ppo', label: 'Percentage Price Oscillator' },
    { value: 'roc', label: 'Rate of change : ((price/prevPrice)-1)*100' },
    { value: 'rocp', label: 'Rate of change Percentage: (price-prevPrice)/prevPrice' },
    { value: 'rocr', label: 'Rate of change ratio: (price/prevPrice)' },
    { value: 'rocr100', label: 'Rate of change ratio 100 scale: (price/prevPrice)*100' },
    { value: 'stoch', label: 'Stochastic' },
    { value: 'stochf', label: 'Stochastic Fast' },
    { value: 'stochrsi', label: 'Stochastic Relative Strength Index' },
    { value: 'trix', label: '1-day Rate-Of-Change (ROC) of a Triple Smooth EMA' },
    { value: 'ultosc', label: 'Ultimate Oscillator' },
    { value: 'willr', label: 'Williams\' %R' },

    //Volaitility Indicators
    { value: 'atr', label: 'Average True Range' },
    { value: 'natr', label: 'Normalized Average True Range' },
    { value: 'trange', label: 'True Range' },


  ];
