export const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '75%',
      height: '75%',
      lineHeight: '75%', 
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '75%',
      padding: '0 6px', 
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
      padding: '0px',
      lineHeight: '75%',  
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '75%',
      alignItems: 'center', 
    }),

    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '8px', 
      paddingTop: '11px', 
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      alignSelf: 'stretch',
      marginBottom: 'auto', 
      marginTop: 'auto', 
    }),

    singleValue: (provided) => ({
      ...provided,
      lineHeight: '120%', 
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? 'blue' : state.isFocused ? 'lightblue' : 'white',
      height: '75%',  
      lineHeight: '75%', 
    }),
    menu: (provided) => ({
      ...provided,
      
    }),
  };
