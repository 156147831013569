import './table.css'; 




function Table({ columns, data, onDetailClick, onDeleteClick }) {
  return (
    <div className="wholetable">
      <table className="strategyTable">
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.key}>{column.header}</th>
            ))}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column) => (
                <td key={column.key}>{row[column.key]}</td>
              ))}
              <td className="actionsColumn">
                <button className="editButton" onClick={() => onDetailClick(row)}>Details</button>
                <button className="deleteButton" onClick={() => onDeleteClick(row)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}


export default Table;