import React, { useState, useEffect } from 'react';
import ChatBar from './rightBar/rightbar';
import EquityCurve from './dataVisuals/equityCurve';
import StatsPanel from './statspanel/statsPanel';
import { fetchData } from '../utils/helpers/fetchHelp';
import { handleInsert } from '../utils/helpers/handleInsert';
import { handleDelete } from '../utils/helpers/handleDelete';
import useAccessToken from '../utils/helpers/getAccessToken';


function PositionSizer ({showToast}) {

    const getAccessToken = useAccessToken();
    const [sizers, setSizers] = useState([]);
    const [selectedSizer, setSelectedSizer] = useState(null);
    const [trades, setTrades] = useState([]);
    const [selectedTrade, setSelectedTrade] = useState(null);



    useEffect(() => {
        const fetchSizer = async () => {
            const accessToken = await getAccessToken();
            fetchData(`${process.env.REACT_APP_SIZERS_API}`, setSizers, setSelectedSizer, accessToken);
        };
        fetchSizer();
    }, []);    

    const handleDeleteSizer = (sizerId) => {
        handleDelete(sizerId, sizers, setSizers, selectedSizer, setSelectedSizer);
    };

  
    const handleInsertSizer = (sizerId, sizerName) => {
      handleInsert(sizerId, sizerName, sizers, setSizers, setSelectedSizer);
    };

    const handleTradeSelect = (trade) => {
        setSelectedTrade(trade);
        const tradeElement = document.getElementById(`trade-${trade.completetradeID}`);
        if (tradeElement) {
          tradeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };	  

    return (
        <>

            <div className = "tradebar" >
            </div>
            <div className="middle-section" >
            <div className="stock-chart" >
            </div>
            <div className="strategy-section">
                
                <div className = "equity-curve-whole">
                <div className = "equity-curve-name">
                </div>
                <div className = "equity-curve">
                <EquityCurve
                     trades={trades}
                     selectedStrategyId={selectedSizer ? selectedSizer.id : null}
                     onTradeSelect = {handleTradeSelect}
                />
                </div>
                </div>
                <div className = "strategy-stats">
                    <StatsPanel
                        selectedStrategyId={selectedSizer ? selectedSizer.id : null}
                    />
                </div>
                </div>

            </div>
        
        
            <div className = "chatbar">
                <ChatBar 
                    selectedSizer={selectedSizer}
                    handleInsert = {handleInsertSizer}
                    showToast = {showToast}
                />
            </div>

        </>
    
    );
    
}

export default PositionSizer;
