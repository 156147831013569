import './App.css';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Core from './components/core';

function AuthHandler() {
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    const location = useLocation(); 
    const getQueryParam = (param) => {
        const params = new URLSearchParams(location.search);
        return params.get(param);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        const action = getQueryParam('action');
        const redirectOptions = {
            screen_hint: action === 'signup' ? 'signup' : 'login',
        };

        loginWithRedirect({authorizationParams: redirectOptions});
        return null; 
    }

    return <Core />;
}

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<AuthHandler />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
