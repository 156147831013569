import React from 'react';
import './profiledropdown.css'; 
import { useAuth0 } from '@auth0/auth0-react';

function ProfileDropdown({dropdownRef}) {
    const { user, isAuthenticated, logout } = useAuth0();
    
    return (
        isAuthenticated && (
        <div className="profile-dropdown" ref={dropdownRef}>
            <div className="profile-header">
                <strong>{user.nickname}</strong>
            </div>
            <div className="profile-options">
                <button className="dropdown-item">Profile Details</button>
                <button
                    style={{ color: 'red' }}
                    className="dropdown-item"
                    onClick={() => {
                        console.log('Logout redirecting to:', process.env.REACT_APP_AUTH0_LOGOUT_URI);
                        logout({ logoutParams: { returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URI } });
                    }}
                >
                    Log Out
                </button>
            </div>
        </div>
        )
    );
}

export default ProfileDropdown;
