import React, { useState, useEffect } from 'react';


function Input ({tag, typee, valueString, valuee, handleInputChange}) {


    return (

        <div className="indicator-details-values23">
            <label style={{marginLeft:"4%"}}>{tag}</label>
            <input type={typee} className="indicator-length-input2"  style={{ marginRight: '0', width: "32%", borderWidth: '1px', borderRadius: '3px' , padding: '1px'}} value={valuee || ""} onChange={(e) => handleInputChange(valueString, e.target.value)}/>
        </div>

    );

};

export default Input;