import React, { useState } from 'react';
import './Operationrow.css';
import { v4 as uuidv4 } from 'uuid';

const OperationRow = ({ id, operation, onOperationChange, onDelete }) => {
  const [showDetails, setShowDetails] = useState(false);
  

  const [operationss, setOperationss] = useState({
    ...operation, 
  });

  const handleInputtChange = (name, value) => {
    const newOperation = { ...operationss, [name]: value };
    setOperationss(newOperation);
    onOperationChange(newOperation); 
  };  

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  
  return (
    <div className="chatbar-condition-row">
      <div className = "chatbar-condition-row-values3" >
      <select className="indicator-dropdown2" value={operationss.comparison} onChange={(e) => handleInputtChange('comparison', e.target.value)}>
        <option value=""></option>
        <option value="Add">Add</option>
        <option value="Subtract">Subtract</option>
        <option value="Multiply">Multiply</option>
        <option value="Divide">Divide</option>
      </select>
      <input type="number" className="indicator-length-input" placeholder="Value" style={{  width: "30%", paddingLeft: '5px' }} value={operationss.valuee} onChange={(e) => handleInputtChange('valuee', e.target.value)}/>
      <button onClick={() => onDelete(id)} className="delete-condition">
        -
      </button>
      </div>
    </div>    
  );
};

export default OperationRow;
