import  MainChart  from '../../backtesting/strategyBuilder/mainChart/chart';
import { version } from '../../../charting_library';
import EquityCurve from '../../backtesting/portfolioBuilder/datavisuals/equityCurve';
import StatsPanel from '../../backtesting/portfolioBuilder/statspanel/statsPanel';
import React, { useState, useEffect } from 'react';
import ListBar from '../../backtesting/utils/listbar/listbar';
import InnerBar from '../../backtesting/utils/innerbar/innerbar';
import DetailsBar from '../utils/detailsBar'
import { fetchData } from '../../backtesting/utils/helpers/fetchHelp';
import { handleInsert } from '../../backtesting/utils/helpers/handleInsert';
import { handleDelete } from '../../backtesting/utils/helpers/handleDelete';
import useAccessToken from '../../backtesting/utils/helpers/getAccessToken';



function ReviewStrategies ( {showToast} ) {

    const getAccessToken = useAccessToken();
    const [strategies, setStrategies] = useState([]);
    const [selectedStrategy, setSelectedStrategy] = useState(null);
    const [trades, setTrades] = useState([]);
    const [selectedTrade, setSelectedTrade] = useState(null);


    useEffect(() => {
      const fetchStrategies = async () => {
        const accessToken = await getAccessToken();
        fetchData(`${process.env.REACT_APP_STRATEGIES_API}`, setStrategies, setSelectedStrategy, accessToken);
      };
      fetchStrategies();
    }, []);

  const handleDeleteStrategy = (strategyId) => {
      handleDelete(strategyId, strategies, setStrategies, selectedStrategy, setSelectedStrategy);
  };
  
    const handleInsertStrategy = (strategyId, StrategyName) => {
      handleInsert(strategyId, StrategyName, strategies, setStrategies, setSelectedStrategy);
  }  

    const handleTradeSelect = (trade) => {
        setSelectedTrade(trade);
        const tradeElement = document.getElementById(`trade-${trade.completetradeID}`);
        if (tradeElement) {
          tradeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };	  

  
  return (
    <>
          <div className = "tradebar">
              <ListBar
                  items={strategies}
                  onItemSelect={setSelectedStrategy}
                  handleDelete={handleDeleteStrategy}
                  selectedItem={selectedStrategy}
                  itemType="Strategies"
                  deleteEndpoint={process.env.REACT_APP_DELETE_STRATEGY_API}
                /> 
  
                <InnerBar
                items={trades}
                setItems={setTrades}
                selectedItem={selectedTrade}
                selectedListId={selectedStrategy ? selectedStrategy.id : null}
                onItemSelect={setSelectedTrade}
                itemType="Trades"
                endpoint={process.env.REACT_APP_LIVE_TRADES_LIST_API}
                queryParamName="strategyId"
                />

          </div>
          <div className="middle-section">
          <div className = "stock-chart">
          {selectedTrade && <MainChart selectedTrade={selectedTrade} selectedStrategy={selectedStrategy}/>}
            </div>
            <div className="strategy-section">
            <div className = "equity-curve-whole">
            <div className = "equity-curve-name">
            </div>
            <div className = "equity-curve">
            <EquityCurve
                 trades={trades}
                 selectedStrategyId={selectedStrategy ? selectedStrategy.id : null}
               onTradeSelect = {handleTradeSelect}
            />
            </div>
            </div>
            <div className = "strategy-stats">
                <StatsPanel
                    selectedStrategyId={selectedStrategy ? selectedStrategy.id : null}
                />
            </div>
            </div>
          </div>
          <div className = "chatbar">
            <DetailsBar/>
          </div>

    </>


  );
  
}


export default ReviewStrategies;
