import { Routes, Route, Navigate } from 'react-router-dom';
import NavigationBar from './navbar/Navbar';
import LiveCore from './livetrading/liveCore';
import Home from './home/home';
import BackTesting from './backtesting/Backtesting';
import Toaster from './toaster/toaster_pop';
import { socket, backtestSocket, initializeSockets, disconnectSockets } from '../socket';
import React, { useState, useEffect } from 'react';
import './core.css'
import { useAuth0 } from '@auth0/auth0-react';
import useAccessToken from './backtesting/utils/helpers/getAccessToken';
import registerUser from '../registerUser';

function Core() {

	const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
	const [toastMessage, setToastMessage] = useState('');
	const [toastMessageType, setToastMessageType] = useState('');
	const getAccessToken = useAccessToken();

	const [isConnected, setIsConnected] = useState(false);
	const [isBacktestConnected, setIsBacktestConnected] = useState(false);
	const [logs, setLogs] = useState([{ message: "No current logs", type: "info2" }]);


	
	useEffect(() => {
		if (isAuthenticated) {
		
        async function initializeApp() {
            try {
                const accessToken = await getAccessToken(); 
                await registerUser(accessToken);
                initializeSockets(); 
            } catch (error) {
                console.error('Error during user registration or socket initialization:', error);
            }
        }


		function onConnect() {
		  setIsConnected(true);
		}
	  
		function onDisconnect() {
		  setIsConnected(false);
		}

		function onBacktestConnect() {
			setIsBacktestConnected(true);
		}

		function onBacktestDisconnect() {
			setIsBacktestConnected(false);
		}

		initializeApp();

		if (socket){
		socket.on('connect', onConnect);
		socket.on('disconnect', onDisconnect);
		}

		if (backtestSocket){
		backtestSocket.on('connect', onBacktestConnect);
		backtestSocket.on('disconnect', onBacktestDisconnect);
		}
		return () => {
		  if (socket) {
		  socket.off('connect', onConnect);
		  socket.off('disconnect', onDisconnect);
		  }

		  if (backtestSocket) {
		  backtestSocket.off('connect', onBacktestConnect);
		  backtestSocket.off('disconnect', onBacktestDisconnect);
		  }
		  disconnectSockets();
		};
		}				
	  }, [isAuthenticated]);

	const addLog = (newMessage, newType) => {
		if (logs.length === 1 && logs[0].message === "No current logs") {
		  setLogs([{ message: newMessage, type: newType }]);
		} else {
		  setLogs([...logs, { message: newMessage, type: newType }]);
		}
	  };

	const showToaster = (message, type) => {
		const now = new Date();  
		const easternTime = new Date(now.toLocaleString("en-US", { timeZone: "America/New_York" }));
		const timeString = easternTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' });	  
		const displayMessage = `${timeString} EST: ${message}`;

		setToastMessage(displayMessage);
		setToastMessageType(type);
		addLog(displayMessage, type);
		setTimeout(() => {
		  setToastMessage('');
		  setToastMessageType('');
		}, 3000);  
	};  


    return (
      <div className="app-container">
        {toastMessage !== '' && (
        <Toaster message={toastMessage} type={toastMessageType} />
        )}

         <div className="navbar">

        <NavigationBar logs={logs}/>
        </div>
        <Routes>
			<Route path="/" element={<Navigate to="/home" />}/>  
			<Route path="/home" element={<Home showToast={showToaster}/>} />
            <Route path="/livetrading/*" element={<LiveCore showToast={showToaster}/>} />
            <Route path="/backtesting/*" element={<BackTesting showToast={showToaster}/>}/>
        </Routes>
      </div>
     );
}

export default Core;