import { useAuth0 } from "@auth0/auth0-react";

const useAccessToken = () => {
    const { getAccessTokenSilently } = useAuth0();

    const getAccessToken = async () => {
        try {
            const accessToken = await getAccessTokenSilently({
                audience: process.env.REACT_APP_API_AUDIENCE,
            });

            return accessToken;
        } catch (error) {
            console.error("Error retrieving access token:", error);
            throw error; 
        }
    };

    return getAccessToken;
};

export default useAccessToken;
